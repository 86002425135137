import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import deleteimg from '../images/delete.png'
import { BASE_URL } from "../../../config/constants";

function EditAddress(props) {
  // console.log(props);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [address, setAddress] = useState(props.address || "");
  const [zipcode, setZipcode] = useState(props.zipcode || "");
  const [close, setClose] = useState("");


  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  // console.log(props);
  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };

  //Delete Address
  const updateAddress = async (id) => {

    
    // console.log(id,address,zipcode);
    const formData = new FormData();
    formData.append("address_id", id);
    // formData.append("address",address);
    {address==="" ? formData.append("address",props.address) : formData.append("address",address) }
    {zipcode==="" ? formData.append("pincode",props.zipcode) : formData.append("pincode",zipcode) }

    // formData.append("pincode",zipcode);


    const url = `${BASE_URL}/Api/updateaddress`;
    setLoading(true);
    setButtonLoading(true);
    axios
      .post(url,formData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setButtonLoading(false);

        if (response.data.status === true) {
          toast.success("Address is Updated", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          props.getAddressInfo();
          closeModal();

        // window.location.reload();
        } else {
          toast.error("Something went wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        //   window.location.reload(false);
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
        setButtonLoading(false);
      });
  };

  
  function closeModal (){
    setClose(props.hide);
  }
  return (
    <div>
      <div class="modal" show fade style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {" "}
                Edit Address
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <div class="row" id="">
                <form>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="address" class="form-label">
                        Full Address
                      </label>
                      <textarea
                        class="form-control"
                        rows="3"
                        id="address"
                        placeholder="Enter Your Full Address"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label for="pincode" class="form-label">
                        Pincode
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="pincode"
                        minLength="6"
                        maxLength="6"
                        placeholder="Enter Your Pincode"
                        onKeyPress={onKeyPressEvent}
                        name="pincode"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                      />
                    </div>
                    <div class="mb-3">
                      {!buttonLoading && (
                        <div class="text-center">
                          <button
                            className="btn buttons loginbtn"
                            type="submit"
                            onClick={() => updateAddress(props.address_id)}
                          >
                            Save
                          </button>
                        </div>
                      )}
                      {buttonLoading && (
                        <div class="">
                          <button
                            role="button"
                            className="btn buttons"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>{" "}
                            Saving...
                          </button>
                        </div>
                      )}
                      {/* <button
                        type="submit"
                        id="close_address"
                        class="btn buttons"
                      >
                        Save
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAddress;
