import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../Loader/Loader";
import Header from "../Home/Header/Header";
import Footer from "../Footer/Footer";
import { BASE_URL } from "../../../config/constants";

function Terms() {
  const [privacyData, setPrivacyData] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);
  const [nullMessage, setNullMessage] = useState(false);

  const get_terms_data = () => {
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    let url = `${BASE_URL}/Api/terms_condition`;

    axios
      .get(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setPrivacyData(response.data.Data);
          setPageLoading(false);
        } else {
          setNullMessage(true);
          setPageLoading(false);

        }

        // setSubCategories(response.data.products[0].Computer);
        // setLaptop(response.data.products[1].Laptop);
        // setGetBanners(response.data.banners);
        // setNotificationCount(response.data.notification_count);
        // localStorage.setItem("key16",Base64.encode(response.data.notification_count))
        // localStorage.setItem("key14",Base64.encode(response.data.cart_count))
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_terms_data();
  }, []);
  return (
    <div>
      <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <div className="container marg_top" >
          <div className="row mb-2 mt-3">
            {pageLoading && (
              <>
                <Loader />
              </>
            )}
            {nullMessage && (
              <>
                <div className="col-md-6 offset-md-3">
                  <div class="address_container" style={{ padding: "20px" }}>
                    <div className="text-center">Terms & Condition Not Found</div>
                  </div>
                </div>
              </>
            )}
            {privacyData &&
              privacyData.map((item, i) => {
                return (
                  <>
                    <div class="col-md-12 mt-3" key={i}>
                      <div class="address_container">
                        <div class="notification_title text-center">
                          {item.title}
                        </div>
                        <div
                          class="notification_text"
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
