import React, { useEffect, useState } from 'react'
import myLoader from './loader.gif'

function Loader({ show }) {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setIsLoading(show);

    if (isLoading) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = '';
    }
  });

  return (
    <>
      {isLoading === true &&
        <div className='loader-container'>
          <div className='loader' >
            <img src={myLoader} alt="my-gif" style={{ height: '80px' }} />
          </div >
        </div >
      }
    </>
  )
}

export default Loader