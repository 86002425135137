import React from 'react'

function NotificationModal(props) {
  // console.log(props)

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };
  return (
    <div className='notification_modal'>
      <div class="modal" show fade style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="exampleModalLabel"> {props.title}</h5>
              <button type="button" class="btn-close" onClick={props.hide}></button>
            </div>
            <div class="modal-body" dangerouslySetInnerHTML={{
              __html: props.desc,
            }}>

            </div>
            {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" onClick={props.hide}>Close</button>
        <button type="button" class="btn btn-primary">Ok</button>
      </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal