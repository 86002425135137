import React, { useState, useEffect } from "react";
import Header from "../Home/Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import { Base64 } from "js-base64";
import Loader from "../Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import EditAddress from "./EditAddress";
import DeleteAddress from "./DeleteAddress";
import { BASE_URL } from "../../../config/constants";

function AllAddress() {
  const [addressData, setAddressData] = useState([]);
  const [nullMessage, setNullMessage] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [show, toggleShow] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [popularData, setPopularData] = useState("");

  const [tempData, setTempData] = useState("");

  let user_id = localStorage.getItem("key1");
  // let noti_count = localStorage.getItem("key16");

  // ------------ Add Address -------------

  const getAddressInfo = () => {
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    // const url = `http://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `${BASE_URL}/Api/address?user_id=${Base64.decode(
      user_id
    )}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setPageLoading(false);

        if (response.data.status === false) {
          setNullMessage(true);
          setPageLoading(false);
          setAddressData("");
        } else {
          setAddressData(response.data.Data);
          setPageLoading(false);
          setNullMessage(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
        setNullMessage(true);
      });
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    getAddressInfo();
  }, []);


  // const [pincode, setPinCode] = useState("");
  // const [address, setAddress] = useState("");

  // const [editModal, setEditModal] = useState(false);
  //Add Address
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const validationSchema = yup.object({
    address: yup
      .string()
      .min(1, "Please Enter Your Address")
      .required("Please Enter Your Address"),
    pincode: yup
      .string()
      .min(1, "Please Enter Your Pincode")
      .required("Please Enter Your Pincode"),
  });

  const initialValues = {
    address: "",
    pincode: "",
  };
  //Confirm Appoinment
  const onSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("user_id", Base64.decode(user_id));

    formData.append("address", values.address);
    formData.append("pincode", values.pincode);

    const url = `${BASE_URL}/Api/add_address`;

    try {
      setButtonLoading(true);

      const data = await axios
        .post(url, formData)
        .then((response) => {
          console.log(response);
          setButtonLoading(false);
          if (response.data.status === false) {
            // navigate(-1)
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success("Address is Added Successfully ", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getAddressInfo();
            setButtonLoading(false);
            toggleShow(false);
            resetForm();
          }

          // window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const notificationModalData = (address_id, address, zipcode) => {
    let modalData = [address_id, address, zipcode];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };

  //Get Data for Delete
  const getDeleteData = (address_id) => {
    console.log(address_id);
    let modalData = [address_id];

    setTempData((item) => [1, ...modalData]);
    return setDeleteModal(true);
  };

  const toggleAddAddress = () => {
    let scrollTo = show;
    if (scrollTo) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 400);
    }
    toggleShow(!show);
  }

  return (
    <div>
      <Header />
      <div id="address_div" className="mt-100">
        <div class="container">
          <div class="row bg-white p-1">
            {pageLoading && (
              <>
                <Loader />
              </>
            )}
            <div class="login_heading mx-2">Saved Addresses</div>
            <hr className="mx-3"></hr>
            <h6 className="mb-4 mx-2">Manage your saved addresses</h6>
            {nullMessage && (
              <>
                <div className="col-md-12">
                  <div class="">
                    <h6 className="text-center">You have no saved addresses.</h6>
                  </div>
                </div>
              </>
            )}
            {addressData &&
              addressData.map((item) => {
                return (
                  <>
                    <div class="col-md-6 justify-content-center">
                      <div
                        class="address_container_div"
                        style={{ padding: "20px" }}
                      >
                        <div class="dropdown address_dots">
                          <div class="dropbtn p-0">
                            <i
                              class="fa-solid fa-ellipsis-vertical"
                              style={{ color: "#0e688d" }}
                            ></i>
                          </div>
                          <div class="dropdown-content address_dots_content">
                            <a
                              id="edit_address"
                              role="button"
                              onClick={() =>
                                notificationModalData(
                                  item.address_id,
                                  item.address,
                                  item.pincode
                                )
                              }
                            >
                              Edit
                            </a>
                            <a
                              role="button"
                              onClick={() => getDeleteData(item.address_id)}
                            >
                              Delete
                            </a>
                          </div>
                        </div>

                        <div class="address_text">
                          <i
                            class="fa-solid fa-location-dot"
                            style={{ color: "#0e688d" }}
                          ></i>
                          &nbsp;&nbsp;{item.address}
                        </div>
                        <div class="address_text">{item.pincode}</div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          {!show && (<div className="row">
            <div className="col-12">
              <div class="mt-3 px-2">
                <button
                  type="button"
                  class="btn buttons sitebtn2"
                  id="add_btn"
                  onClick={() => { toggleAddAddress() }}
                >
                  {!show ? 'Add' : 'Cancel'}
                </button>
              </div>
            </div>
          </div>)}
          {show && (
            <>
              <div
                class="row mt-5 mb-5 px-2"
                style={{ display: show ? "block" : "none" }}
              >
                <div className="col-md-12">
                  <h5 className="mb-4 px-2">Add a new address</h5>
                  <form onSubmit={formik.handleSubmit} className="address_container_div">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="address" class="form-label">
                          Full Address
                        </label>
                        <textarea
                          class="form-control"
                          rows="3"
                          id="address"
                          placeholder="Enter Your Full Address"
                          name="address"
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          onChange={formik.handleChange}
                        ></textarea>
                        <p style={{ color: "red" }}>
                          {formik.touched.address && formik.errors.address
                            ? formik.errors.address
                            : ""}
                        </p>
                      </div>
                      <div class="mb-3">
                        <label for="pincode" class="form-label">
                          Pincode
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="pincode"
                          minLength="6"
                          maxLength="6"
                          placeholder="Enter Your Pincode"
                          onKeyPress={onKeyPressEvent}
                          name="pincode"
                          onBlur={formik.handleBlur}
                          value={formik.values.pincode}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.pincode && formik.errors.pincode
                            ? formik.errors.pincode
                            : ""}
                        </p>
                      </div>
                      <div class="d-flex mb-3">
                        {!buttonLoading && (
                          <div class="">
                            <button className="btn buttons sitebtn2" type="submit" style={{ marginRight: '10px' }}>
                              Save
                            </button>
                            {show && (<button className="btn buttons sitebtn2 " type="button"
                              id="cancel_btn"
                              onClick={() => { toggleAddAddress() }}>
                              Cancel
                            </button>)}
                          </div>
                        )}
                        {buttonLoading && (
                          <div class="">
                            <button
                              type="reset"
                              role="button"
                              className="btn buttons loginbtn"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Saving...
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {discriptionModal === true ? (
            <EditAddress
              hide={() => setDiscriptionModal(false)}
              address_id={popularData[1]}
              address={popularData[2]}
              zipcode={popularData[3]}
              getAddressInfo={getAddressInfo}
            />
          ) : (
            ""
          )}
          {deleteModal === true ? (
            <DeleteAddress
              hide={() => setDeleteModal(false)}
              address_id={tempData[1]}
              getAddressInfo={getAddressInfo}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllAddress;
