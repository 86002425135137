import { RAZORPAY_KEY, RAZORPAY_SECRET } from "../config/constants";

function PaymentService(gateway = 'razorpay', amount, successCallback, failureCallback, extra = {}) {
    this.gateway = gateway;
    this.amount = Number(amount);
    this.extra = extra;
    this.successCallback = successCallback;
    this.failureCallback = failureCallback;

    this.payment_status = 'FAILED';
    this.payment_ref = "";
}

PaymentService.prototype.validate = function () {

    if (this.gateway === "") {
        return;
    }

    if (typeof this.amount !== 'number' || this.amount <= 0) {
        return;
    }

    if (this.successCallback === undefined || this.failureCallback === undefined) {
        return;
    }

}


PaymentService.prototype.paywithRazorPay = function () {
    let thisRef = this;
    thisRef.validate();
    var options = {
        key: RAZORPAY_KEY,
        key_secret: RAZORPAY_SECRET,
        amount: thisRef.amount * 100,
        currency: "INR",
        name: "Care Master ",
        description: "Care Master Service",

        handler: function (response) {
            console.log(response);
            if (response.razorpay_payment_id) {
                thisRef.payment_status = "SUCCESS";
                thisRef.payment_ref = response.razorpay_payment_id;

                let resullt = {
                    'payment_status': thisRef.payment_status,
                    'payment_ref': thisRef.payment_ref
                };

                thisRef.successCallback(resullt);
            }

        },

        prefill: {
            // name: user_name,
            // contact: mobile,
            // email: user_email,
        },
        notes: {
            // address: retrivedData[0],
        },
        theme: {
            color: "#3399cc",
        },
    };
    var pay = new window.Razorpay(options);
    pay.open();
    pay.on("payment.failed", function (resp) {
        let resullt = {
            'payment_status': thisRef.payment_status,
            'payment_ref': thisRef.payment_ref
        };
        thisRef.failureCallback(resullt);
    });
}


// export default PaymentService;
export default PaymentService;