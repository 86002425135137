import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { AppSettingsContext } from "../Context/AppSettingsProvider";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

let userName = localStorage.getItem("key2");
let userEmail = localStorage.getItem("key5");

function FloatingFooter() {
  const cartItems = useSelector((state) => state.cart.items);
  const cartTotalAmount = useSelector((state) => state.cart.cartTotalAmount);

  const navigate = useNavigate();
  const location = useLocation();

  const { cartUpdated } = useContext(AppSettingsContext);
  const [cartTotal, setCartTotal] = useState(0);
  const [hideonPage, setHideOnPage] = useState(false);
  const [currentPath, setCurrentPath] = useState("home");


  const calculateCartTotal = () => {
    // let cartTotal = 0;
    // cartItems.forEach((item) => {
    //   cartTotal = cartTotal + (Number.parseFloat(item.price) * Number.parseInt(item.quantity));
    // });
    setCartTotal(Number.parseInt(cartTotalAmount));
  }

  const goToCart = () => {
    navigate("home/mycart");
  }

  useEffect(() => {
    if (cartUpdated) {
      calculateCartTotal();
    }

    if (location.pathname == '/') {
      setCurrentPath('home');
    } else {
      setCurrentPath(location.pathname);
    }
    if (['/contactus', '/aboutus'].includes(location.pathname) || (location.pathname.includes('/mycart/chooseaddress'))
      || (location.pathname.includes('/home/mycart') || location.pathname.match(/^\/my_orders/) != null)
    ) {
      setHideOnPage(false);
    } else {
      setHideOnPage(true);
    }
    userName = localStorage.getItem("key2");
    userEmail = localStorage.getItem("key5");

  }, [location, cartItems]);

  return (
    <>
      {/* Desktop cart icon */}
      {(hideonPage && (cartTotal && cartTotal > 0)) ? <div>
        <div id="desktop-floating-footer" className="container-fluid fixed-bottom p-1 hidden-xs">
          <div className="row">
            <div className="col-12">
              <div className="float-end" >
                <button type="button" className="btn button sitebtn2 d-flex justify-content-center align-item-center p-n1" onClick={goToCart}>
                  <span className="px-2">Total (₹) : {Number.parseFloat(cartTotal).toFixed(2)} *</span>
                  <i className="fas fa-long-arrow-right px-2 text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div > : ""
      }
      {/* Mobile footer icons */}
      {isMobile && (<div>
        <div id="mobile-floating-footer" className="container-fluid fixed-bottom p-3 d-md-none d-lg-none">
          <div className="row">
            <div className="col-3">
              <Link
                className="nav-link footer-icon footer-icon-active"
                to={`/`}
                onClick={() => setCurrentPath('home')}
              >
                <i className={currentPath == 'home' ? 'fas fa-home  site-color' : 'fas fa-home'}></i>
                <sub>Home</sub>
              </Link>
            </div>
            <div className="col-3">
              <Link
                className="nav-link footer-icon"
                to={userName ? '/myorders' : '/home/mycart'}
                onClick={() => setCurrentPath(userName ? `myorders` : 'mycart')}
              >
                <i className={currentPath.includes('myorders', 'mycart') ? 'fas fa-calendar  site-color' : 'fas fa-calendar'}></i>
                <sub>Bookings</sub>
              </Link>
            </div>
            <div className="col-3">
              <Link
                className="nav-link footer-icon"
                to={
                  userEmail
                    ? `/user_profile/${userName}/${userEmail}`
                    : `/user_profile/${userName}`

                }
                onClick={() => setCurrentPath('profile')}
              >
                {/* <i className="fas fa-user-circle"></i> */}
                <i className={currentPath.includes('profile') ? 'fas fa-user-circle  site-color' : 'fas fa-user-circle'}></i>
                <sub>Profile</sub>
              </Link>
            </div>
            <div className="col-3">
              <Link
                className="nav-link footer-icon"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                to="#"
                role="button"
                onClick={() => setCurrentPath('menu')}
              >
                <i className={currentPath == 'menu' ? 'fas fa-bars  site-color' : 'fas fa-bars'}></i>
                <sub>Menu</sub>
              </Link>
            </div>
          </div>
        </div>
      </div >)
      }
    </>
  );
}

export default FloatingFooter;
