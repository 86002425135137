import React, { useContext, useEffect, useState } from "react";
import logo from "../images/logo.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { Base64 } from "js-base64";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BASE_URL } from "../../../config/constants";
import BackButton from "../Home/Header/BackButton";
import { AppSettingsContext } from "../Context/AppSettingsProvider";
import { useDispatch, useSelector } from "react-redux";
import { addToCart as sAddToCart, removeFromCart as sRemoveFromCart } from "../ApplicationState/CartReducer";


function ServiceCategories() {

  const cartItems = useSelector((state) => state.cart.item);
  const dispatch = useDispatch();

  const [categoryData, setCategoryData] = useState([]);
  const { setCartUpdated } = useContext(AppSettingsContext);
  const [serviceValues, setServiceValues] = useState([]);
  const [nullMessage, setNullMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [isDisable, setIsDisable] = useState(false);

  let user_id = localStorage.getItem("key1");

  const params = useParams();

  const getSingleProductData = (name) => {
    name = name.replace(/%20/g, " ");
    name = name.replace(/&/g, "%26");
    name = name.replace(/%20%20%20/g, " ");

    // encodeURIComponent(name);
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    const url = `${BASE_URL}/Api/services?category=${name}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        setPageLoading(false);
        if (response.data.status === false) {
          setNullMessage(true);
          localStorage.setItem("services", JSON.stringify([]));
        } else {
          setServiceValues(response.data.Services);
          setIsDisable(false);
          setNullMessage(false);
          setPageLoading(false);
          if (response.data.Category_data.length > 0) {
            setCategoryData(response.data.Category_data[0]);
          }
          localStorage.setItem("services", JSON.stringify(response.data.Services));
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getSingleProductDataByID = (async (name) => {
    name = name.replace(/%20/g, " ");
    name = name.replace(/&/g, "%26");
    name = name.replace(/%20%20%20/g, " ");
    // setPageLoading(true);
    try {
      const apiResponse = await Promise.all([
        axios.get(`${BASE_URL}/Api/services?category=${name}`),
        axios.get(
          `${BASE_URL}/Api/cartlist?user_id=${Base64.decode(user_id)}`
        ),
      ]);
      setPageLoading(false);
      // console.log(apiResponse);

      if (apiResponse[0].data.status === false) {
        setNullMessage(true);
        localStorage.setItem("services", JSON.stringify([]));
      } else {
        const categoryItemList = apiResponse[0].data.Services ?? [];
        const cartList = apiResponse[1].data.Services ?? [];

        localStorage.setItem("services", JSON.stringify(categoryItemList));

        categoryItemList.forEach((menuItem, index) => {
          categoryItemList[index].isCartList = false;
          categoryItemList[index].quantity = 0;
        });


        let tmpCartLength = 0;
        let categoryMatch = false;
        categoryItemList.forEach((menuItem, index) => {
          cartList.forEach((cartItem) => {
            if (cartItem && menuItem.id === cartItem.service_id) {
              categoryMatch = true;
              categoryItemList[index].isCartList = true;
              categoryItemList[index].quantity = cartItem.qty;
              tmpCartLength += Number.parseInt(cartItem.qty);
            }
          });
        });

        setServiceValues(categoryItemList);
        setIsDisable(false);

        localStorage.setItem("cartLength", tmpCartLength);
        if (categoryMatch) {
          localStorage.setItem("cart", JSON.stringify(cartList));
          setCartUpdated(true);
        }
        if (apiResponse[0].data.Category_data.length > 0) {
          setCategoryData(apiResponse[0].data.Category_data[0]);
        }
      }

    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  });

  useEffect(() => {
    if (params.service_name && user_id) {
      getSingleProductDataByID(params.service_name);
    } else {
      getSingleProductData(params.service_name);
    }
  }, [params, cartItems, user_id]);


  //Update Cart Items

  const cartUpdate = async (action, item, quantity) => {
    setIsDisable(true);
    item.quantity = quantity;
    item.fromCart = false;
    if (action === 'add') {
      dispatch(sAddToCart(item)).then(() => {
        getSingleProductDataByID(params.service_name);
      });
    } else if (action === 'remove') {
      dispatch(sRemoveFromCart(item)).then(() => {
        getSingleProductDataByID(params.service_name);
      });
    }
    return;
  };

  return (
    <div className="site-page">
      <div className="cate_head">Travel & Visit Fee &#x20B9; 99</div>
      <BackButton margin="yes"></BackButton>
      <div>
        <div className="container mb-3">
          <div className="row">
            {pageLoading && (
              <>
                <Loader />
              </>
            )}
            {nullMessage && (
              <>
                <div className="col-md-6 offset-md-3">
                  <div className="card px-4 cate_card p-4">
                    <div className="text-center">No Data Availble </div>
                  </div>
                </div>
              </>
            )}
            {categoryData.name ? <div className="row pt-2">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-8">
                    <h3 className="serviceInfoTxt">{categoryData.name} Services</h3>
                    <hr />
                    <p className="serviceInfoTxtInfo category-info">{categoryData.description_long}</p>
                  </div>
                  <div className="col-sm-4 d-flex justify-content-around align-items-center category-info ">
                    <LazyLoadImage loading="true"
                      src={`${BASE_URL}/uploads/${categoryData.photo}`}
                      alt={categoryData.name}
                      key={'product-image'}
                      className="category-description-logo category-info"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
              </div>
            </div> : ""}
            {serviceValues &&
              serviceValues.map((items, i) => {
                // console.log(items);
                return (
                  <>
                    <div className="col-md-6" key={'services-' + i}>
                      <div className="card px-4 cate_card" key={'cate_card' + i}>
                        <div className="d-flex align-items-center" key={'cate_card_div' + i}>
                          <div>
                            <LazyLoadImage loading="true"
                              effect="blur"
                              src={`${BASE_URL}/uploads/${items.photo}`}
                              alt={items.name}
                              key={'product-image' + i}
                              placeholderSrc={logo}
                              width={'80px'}
                              height={'80px'}
                            />
                          </div>
                          <div className="card-body" key={'card-body' + i}>
                            <div className="card-title text-uppercase">
                              {items.name}
                            </div>
                            <div className="row">
                              <div className="col-7">
                                <h4 className="">
                                  &#x20B9;{items.price}/-
                                </h4>
                              </div>
                              {/* {items?.isCartList ? ( */}
                              <div className="col-4 d-flex justify-content-center">
                                <input
                                  type="button"
                                  value="-"
                                  className="qtyminus minus"
                                  field="quantity"
                                  onClick={() => {
                                    if (items.quantity >= 1) {
                                      cartUpdate('remove', items, Number(items.quantity) - 1)
                                    }
                                  }
                                  }
                                  disabled={isDisable}
                                />
                                <input
                                  type="text"
                                  name="quantity"
                                  value={items.quantity}
                                  className="qty"
                                  disabled
                                />
                                <input
                                  type="button"
                                  value="+"
                                  className="qtyplus plus"
                                  field="quantity"
                                  onClick={() =>
                                    cartUpdate('add', items, Number(items.quantity) + 1)
                                  }
                                  disabled={isDisable}
                                />
                              </div>
                              {/* ) : (
                                  
                                )} */}
                            </div>
                            <div
                              className="card-text mb-3 mt-3"
                              style={{ textAlign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: items.description,
                              }}
                              title={(items.description)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="row pt-4 ">
            <div className="col-sm-12">
              <h3 className="serviceInfoTxt mb-3">Why choose {categoryData.name} Services from us? </h3>
              <p className="serviceInfoTxtInfo">{categoryData.description_long}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCategories;
