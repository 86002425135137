import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BASE_URL } from "../../../config/constants";
import Services from "../Home/Services/Services";


function ServicesCategoriesCommon() {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    get_category_data();
    window.scrollTo(0, 0);
  }, []);

  const get_category_data = () => {
    let formData = new FormData();
    let url = `${BASE_URL}/Api/show_category`;
    axios
      .get(url, formData)
      .then((response) => {
        console.log(response);
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div class="container" id="">
        <div class="row justify-content-center mt-4">
          <div className="col-md-12 mt-4">
            <div className="text-center">You can begin by selecting services from below categories</div>
            <Services categories={categories} pageLoading={false} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesCategoriesCommon;
