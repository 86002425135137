import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Zipcode from "../Zipcode/Zipcode";
// import Login from "../Login/Login";
import Home from "../Home/Parent/Home";
import ServiceCategories from "./../ServiceCategories/ServiceCategories";
import NotFoundPage from "../404Page/NotFoundPage";
import MyCart from "../MyCart/MyCart";
import ProfileUpdate from "../ProfileUpdate/ProfileUpdate";
import AllAddress from "../MyAddress/AllAddress";
import Notification from "../AllNotifications/Notification";
import About from "../AboutUs/About";
import Contact from "../Contactus/Contact";
import Privacy from "../PrivacyPolicy/Privacy";
import Terms from "../TermsConditions/Terms";
import ChooseAddress from "../MyAddress/ChooseAddress";
import CartLocalStorage from "../ServiceCategories/CartLocalStorage";
import DateTime from "../DateTime/DateTime";
import MyOrder from "../MyOrder/MyOrder";
import MyOffers from "../MyOffers/MyOffers";
import AllOrders from "../MyOrder/AllOrders";
import Reschedule from "../MyOrder/Reschedule";
import Header from "../Home/Header/Header";
import ProtectedComp from "../ProtectedComponent/ProtectedComp";
import SearchPageUser from "../Home/Carausel/SearchedPageUser";
import SearchPageLocal from "../Home/Carausel/SearchPageLocal";
import Footer from "../Footer/Footer";
import FloatingFooter from "../Footer/FloatingFooter";
import OrderConfirmation from "../OrderConfirmation/OrderConfirmation";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import { AppSettingsProvider } from "../Context/AppSettingsProvider";
import { CartProvider } from "../Context/CartProvider";


function AppContainer() {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (true) {
            dismissMenu();
        }
    }, [location]);

    const [loaderShow, setLoaderShow] = useState(false);

    // in mobile view close the menu
    const dismissMenu = () => {
        document.getElementById('navbarNavDropdown').classList.remove('show');
    }

    return (
        <AppSettingsProvider>
            <Loader show={loaderShow}></Loader>
            <CartProvider>
                <div className="">
                    {/* <Router> */}
                    <ToastContainer transition={Flip} />
                    <Header />
                    <Routes>
                        {/* <Route exact path="/login" element={<Login />} /> */}
                        <Route element={<ProtectedComp />}>
                            <Route
                                exact
                                path="/user_profile/:name/:email"
                                element={<ProfileUpdate />}
                            />
                            <Route exact path="/user_profile/:name" element={<ProfileUpdate />} />

                        </Route>

                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/aboutus" element={<About />} />
                        <Route exact path="/contactus" element={<Contact />} />
                        <Route
                            exact
                            path="/my_orders/:purchase_id/:service_id/all_orders"
                            element={<AllOrders />}
                        />
                        <Route
                            exact
                            path="/my_orders/:purchase_id/reschedule"
                            element={<Reschedule />}
                        />
                        <Route exact path="/home/mycart" element={<MyCart />} />

                        <Route element={<ProtectedComp />}>
                            <Route exact path="/myorders" element={<MyOrder />} />
                            <Route exact path="/myoffers" element={<MyOffers />} />
                            <Route exact path="/myaddress" element={<AllAddress />} />
                        </Route>

                        <Route exact path="/mynotifications" element={<Notification />} />
                        <Route exact path="/privacy_policy" element={<Privacy />} />
                        <Route exact path="/terms_conditions" element={<Terms />} />

                        <Route
                            exact
                            path="/services/:service_name/items"
                            element={<CartLocalStorage />}
                        />

                        <Route
                            exact
                            path="/services/:service_name"
                            element={<ServiceCategories />}
                        />

                        <Route
                            exact
                            path="/categories/:service_id/:search_text"
                            element={<SearchPageUser />}
                        />
                        <Route
                            exact
                            path="/categories/:service_id/:search_text/local"
                            element={<SearchPageLocal />}
                        />


                        <Route
                            exact
                            path="/home/:user_id/mycart/chooseaddress"
                            element={<ChooseAddress />}
                        />
                        <Route
                            exact
                            path="/home/:user_id/mycart/chooseaddress/date_time"
                            element={<DateTime />}
                        />
                        <Route
                            exact
                            path="/home/:user_id/mycart/chooseaddress/date_time/OrderConfirmation"
                            element={<OrderConfirmation />}
                        />
                        <Route path="*" element={<NotFoundPage />} status={404} />
                    </Routes>
                    <FloatingFooter />
                    <Footer />
                    {/* </Router> */}
                </div>
            </CartProvider>
        </AppSettingsProvider>
    )

}

export default AppContainer;