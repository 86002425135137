import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../config/constants";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";

import { Provider } from "react-redux";
import store from "../ApplicationState/CartStore";

function CartProvider({ children }) {

    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}

export { CartProvider };