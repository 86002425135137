import React from "react";
import Img1 from "../../images/static/cleaning.webp";
import Img2 from "../../images/static/electrical.webp";
import Img3 from "../../images/static/ac-repair.webp";
import PlumberImg from "../../images/static/plumbing.webp";
import PlumberImg2 from "../../images/static/plumbing-1.webp";
import PlumberImg3 from "../../images/static/plumbing-2.webp";
import CarpentorImg1 from "../../images/static/carpentor-1.webp";
import CarpentorImg2 from "../../images/static/carpentor-2.webp";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

function ServicesInfo() {
  return (
    <div className="100-vh">
      <div class="container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-2 mb-5 mt-3">Popular Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <OwlCarousel items={1} center={true} responsive={{
              0: {
                items: 1,
                nav: false
              },
              600: {
                items: 2,
                nav: false
              },
              1000: {
                items: 2,
                nav: false
              }
            }}
              className="owl-theme" loop={true} margin={10} autoplay autoplaySpeed={2000} lazyLoad={true} autoplayTimeout={3000} >
              <div ><img className="img" src={Img1} alt="img1" /></div>
              <div><img className="img" src={Img2} alt="img2" /></div>
              <div><img className="img" src={Img3} alt="img3" /></div>
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            {/* <div className="container mb-5">
              <h2 className="text-center mb-2 mb-5 mt-3">Popular Services</h2>
              <div className="row">
                <OwlCarousel items={1} center={true} responsive={{
                  0: {
                    items: 1,
                    nav: true
                  },
                  600: {
                    items: 3,
                  },
                  1000: {
                    items: 1,
                  }
                }}
                  className="owl-theme" loop={true} margin={10} autoplay autoplaySpeed={1000} lazyLoad={true} autoplayTimeout={3000} >
                  <div ><img className="img" src={Img1} /></div>
                  <div><img className="img" src={Img2} /></div>
                  <div><img className="img" src={Img3} /></div>
                </OwlCarousel>
              </div>
            </div> */}
          </div>
          <div id="serviceInfo" className="serviceInfo" >
            <div className="container mt-5 mb-5">
              <div className="row mb-3">
                <div className="col-12">
                  <h2 className="serviceInfoTxt">Carpenter Services</h2>
                  <hr />
                  <p className="serviceInfoTxtInfo">
                    Are you looking for a reliable and skilled carpenter in the city? Look no further than us! We offer the best carpenter services in the city, whether you need furniture repair, home renovation, or custom woodworking. We have a team of experienced and professional carpenters who can handle any project with ease and efficiency. Contact us today and get a free quote for your carpentry needs!
                  </p>
                  <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                      <LazyLoadImage src={CarpentorImg1} alt="Image 3" className="img-fluid serviceImgFixed" ></LazyLoadImage>
                    </div>
                    <div className="col-12 col-md-4">
                      <LazyLoadImage src={CarpentorImg2} alt="Image 2" className="img-fluid serviceImgFixed" ></LazyLoadImage>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 mt-3">
                  <h2 className="serviceInfoTxt">AC Repair services </h2>
                  <hr />
                  <p className="serviceInfoTxtInfo">
                    If you are looking for a reliable and affordable AC repair service in your city, you have come to the right place. We are a team of experienced and certified technicians who can handle any type of AC problem, whether it is a split AC or a window AC. We offer a range of AC services, such as AC installation, removal, servicing, gas filling, leakage repair, and more. We use the latest tools and technologies to ensure your AC is running smoothly and efficiently. We also provide a 30-day service warranty and transparent pricing for your peace of mind. To book our AC repair service, just give us a call or fill in our online form. We will respond to your query within minutes and send our technicians to your doorstep at your convenient time slot. Don't let the summer heat ruin your comfort and mood. Contact us today and get your AC fixed by the best AC repair service in your city.
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <h2 className="serviceInfoTxt">Appliance Repair services </h2>
                  <hr />
                  <p className="serviceInfoTxtInfo">
                    If you're looking for a reliable and affordable appliance repair service, look no further than us. We are a team of experienced and certified technicians who can handle any kind of appliance problem, from refrigerators to ovens to washers and dryers. Whether you need a simple fix or a major overhaul, we can get the job done quickly and efficiently. We offer same-day service, free estimates, and a 100% satisfaction guarantee. No matter what brand or model of appliance you have, we can repair it with our extensive inventory of parts and tools. Don't let a broken appliance ruin your day. Call us today and let us take care of it for you.
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <h2 className="serviceInfoTxt">Plumbing services </h2>
                  <hr />
                  <p className="serviceInfoTxtInfo">
                    are you looking for a plumbing service provider in Mumbai? If so, you have come to the right place. I can help you find the best plumber for your needs, whether it's for a leaky tap, a blocked drain, a new shower installation, or anything else. There are many plumbing service companies available in Mumbai, each with their own strengths and specialties. but we offer quality work at reasonable prices.
                  </p>
                  <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                      <LazyLoadImage src={PlumberImg} alt="Image 3" className="img-fluid serviceImgFixed" ></LazyLoadImage>
                    </div>
                    <div className="col-12 col-md-4">
                      <LazyLoadImage src={PlumberImg2} alt="Image 2" className="img-fluid serviceImgFixed" ></LazyLoadImage>
                    </div>
                    <div className="col-md-4 d-none d-md-block">
                      <LazyLoadImage src={PlumberImg3} alt="Image 3" className="img-fluid serviceImgFixed" ></LazyLoadImage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <div className="container mb-4">
          <div className="row">
            <div
              id="carouselExampleControls"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
              data-bs-interval="1000"
              style={{ marginTop: "59px" }}
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={banner01} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={banner02} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item active">
                  <img src={banner03} className="d-block w-100" alt="..." />
                </div>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesInfo;
