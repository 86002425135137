import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Base64 } from "js-base64";
import CancelOrderModal from "./CancelOrderModal";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../config/constants";
import { AppSettingsContext } from "../Context/AppSettingsProvider";

function MyOrder() {

  const { mobileview } = useContext(AppSettingsContext);

  const [cartData, setCartData] = useState([]);
  const [discriptionModal, setDiscriptionModal] = useState(false);

  const [popularData, setPopularData] = useState("");
  const [ratingModal, setRatingModal] = useState(false);

  const [ratingData, setRatingData] = useState("");

  const [nullMessage, setNullMessage] = useState(false);
  const [cartPage, setCartPage] = useState(false);

  const [loading, setLoading] = useState(false);

  let user_id = localStorage.getItem("key1");


  const loadCartdata = async (event) => {
    let formData = new FormData(); //formdata object

    // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
    const url = `${BASE_URL}/Api/orderhistory?user_id=${Base64.decode(
      user_id
    )}`;

    setLoading(true);
    const data = await axios
      .get(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          setNullMessage(true);

          setLoading(false);
          setCartPage(false);
          localStorage.removeItem("cartLength");
          localStorage.removeItem("cart");
        } else {
          setLoading(false);
          setCartPage(true);
          setCartData(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCartdata();
  }, []);

  const notificationModalData = (purchase_id) => {
    let modalData = [purchase_id];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };

  const ratingModalData = (purchase_id, service_id) => {
    let ratingmodalData = [purchase_id, service_id];

    setRatingData((item) => [1, ...ratingmodalData]);
    return setRatingModal(true);
  };

  // VIEW ORDER //
  const allItemsdata = (id) => {
    let formData = new FormData(); //formdata object

    const url = `${BASE_URL}/Api/order_details`;

    setLoading(true);
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          toast.error("Something went wrong ", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setLoading(false);
          setCartPage(true);
          setCartData(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };


  return (
    <div className="site-page">
      <div id="" className="mt-100" >
        <div class="container">
          <div class="login_heading text-center mb-4">Order History</div>
          {/* <hr /> */}
          <h6 className="mb-4 text-center">(Note : Your last 20 orders will appear here)</h6>
          <div class="row address_row mt-5">
            {nullMessage && (
              <>
                <div class="col-lg-12 d-flex justify-content-center">
                  <div class="">You have no past orders</div>
                </div>
                <div className="col-md-12 mt-5">
                  <div className="d-flex justify-content-center">
                    <Link to="/" className='btn buttons sitebtn2 mx-2'> <i className="fa-solid fa-backward"></i>&nbsp;&nbsp;Back to home</Link>
                  </div>
                </div>
              </>
            )}
            {loading && (
              <>
                <Loader />
              </>
            )}
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            {cartData &&
              cartData.map((item) => {
                const timeString = item.booking_time;
                // Prepend any date. Use your birthday.
                const timeString12hr = new Date(
                  "1970-01-01T" + timeString + "Z"
                ).toLocaleTimeString("en-US", {
                  timeZone: "UTC",
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                });

                // console.log(timeString12hr);

                return (
                  <>
                    <div class="col-md-10 order_col">
                      <div class="address_card">
                        {item.status !== "CANCELLED" ||
                          item.status !== "DONE" ? (
                          <>
                            <div class="dropdown address_dots">
                              <div class="dropbtn p-0">
                                <div class="dropbtn p-0">
                                  <i class="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                              </div>
                              <div class="dropdown-content address_dots_content">
                                <Link
                                  to={`/my_orders/${item.purchase_id}/${item.service_id}/all_orders`}
                                >
                                  View
                                </Link>

                                <Link
                                  to={`/my_orders/${item.purchase_id}/reschedule`}
                                >
                                  Reschedule
                                </Link>
                                <a
                                  role="button"
                                  onClick={() =>
                                    notificationModalData(item.purchase_id)
                                  }
                                >
                                  Cancel
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {item.status === "DONE" ? (
                          <>
                            <div class="dropdown address_dots">
                              <div class="dropbtn p-0">
                                <div class="dropbtn p-0">
                                  <i class="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                              </div>
                              <div class="dropdown-content address_dots_content">
                                <Link
                                  to={`/my_orders/${item.purchase_id}/${item.service_id}/all_orders`}
                                >
                                  View
                                </Link>
                                {/* <a
                                  role="button"
                                  onClick={() =>
                                    ratingModalData(item.purchase_id,item.service_id)
                                  }
                                >
                                  Rating
                                </a> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <Link
                          to={`/my_orders/${item.purchase_id}/${item.service_id}/all_orders`}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <h4 >
                                Order Id : <span>{item.purchase_id}</span>
                              </h4>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              Category : {item.category}
                            </div>
                            {/* <div className={mobileview ? 'col-md-6' : 'col-md-6 text-end'}>
                            <h6>
                              Booking Date :{" "}
                              <span className="mr-4">
                                {item.booking_date &&
                                  item.booking_date
                                    .split("-")
                                    .reverse()
                                    .join("-")}
                              </span>
                            </h6>
                          </div> */}
                            {/* <hr /> */}
                          </div>
                          <div class="row" style={{ fontSize: '12px !important' }}>
                            <div class="col-4 m-auto">
                              <div class="text-center">
                                <span className="mr-4">
                                  {item.booking_date &&
                                    item.booking_date
                                      .split("-")
                                      .reverse()
                                      .join("-")}
                                </span>
                                {/* <div>
                                Service Name : <span>{item.service_name}</span>
                              </div>
                              <div>
                                Mobile Number : <span>+91 {item.mobile}</span>
                              </div>
                              <div>
                                Address : <span>{item.address}</span>
                              </div>
                              <div>
                                Job Code : <span>{item.job_code}</span>
                              </div> */}
                              </div>
                            </div>
                            <div class="col-3 m-auto">
                              <div class="text-center">
                                <div>
                                  <span>{timeString12hr}</span>
                                </div>
                                {/* <div>
                                Price : <span>₹ {item.price}</span>
                              </div> */}
                                {/* <div>
                                Quantity : <span>{item.qty}</span>
                              </div> */}
                                {/* <div>
                                Total Items Price :{" "}
                                <span>₹ {item.sum_total}</span>
                              </div>
                              <div>
                                Travelling & Convinience:{" "}
                                <span>₹ {item.tcc}</span>
                              </div> */}
                                {/* <div>
                                Total :{" "}
                                <span>
                                  ₹ {Number(item.sum_total) + Number(item.tcc)}
                                </span>
                              </div> */}
                              </div>
                            </div>
                            <div class="col-5 m-auto">
                              <div class="text-center">
                                <span
                                  className={
                                    item.status === "DONE"
                                      ? "btn btn-success"
                                      : item.status === "CANCELLED"
                                        ? "btn btn-danger"
                                        : "btn buttons border"
                                  }
                                >
                                  {item.status === "" ? "Pending" : item.status?.toLowerCase()}&nbsp;
                                  <i
                                    class={
                                      item.status === "DONE"
                                        ? "fa-solid fa-circle-check"
                                        : item.status === "CANCELLED"
                                          ? "fa-solid fa-xmark"
                                          : "fa-solid fa-bell"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        {/* {deleteModal === true ? (
        <CancelOrderModal
          hide={() => setDeleteModal(false)}
          order_id={tempData[1]}
        />
      ) : (
        ""
      )} */}
      </div>
      {discriptionModal && (
        <CancelOrderModal
          hide={() => setDiscriptionModal(false)}
          purchase_id={popularData[1]}
          loadCartdata={loadCartdata}
        />
      )}
    </div>
  );
}

export default MyOrder;
