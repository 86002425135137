import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function ProtectedComp() {
  const user_id = localStorage.getItem("key1");
  return (
    <div>
      {user_id ? <Outlet /> : <Navigate to="/?logincheck=1" />}
    </div>
  )
}

export default ProtectedComp