import React from "react";
import { Link } from "react-router-dom";
import whatapp from "../images/Group 2.png";
import insta from "../images/Group 1.png"
import fb from "../images/Group 3.png";

function Footer() {
  return (
    <div>
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex footer_col">
              <div>
                <div className="footer_heading">Get In Touch</div>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa-solid fa-location-dot footer_icon"></i>
                  <div className="footer_text">
                    Office No.345, 3th floor, BMC Building, 78 business park,
                    nr. Bhandup station road, Bhandup, <br /> West Mumbai, India - 400078
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa-solid fa-phone footer_icon"></i>
                  <div className="footer_text">+91 8369441674</div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <i className="fa-solid fa-envelope footer_icon"></i>
                  <div className="footer_text">caremasterindia@gmail.com</div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex footer_col footer_col_services">
              <div>
                <div className="footer_heading">Our Services</div>
                <a href="" className="footer_link footer_text">
                  {" "}
                  Carpenter
                </a>{" "}
                <br />
                <a href="" className="footer_link footer_text">
                  {" "}
                  Plumber
                </a>{" "}
                <br />
                <a href="" className="footer_link footer_text">
                  {" "}
                  AC Repairing Services
                </a>{" "}
                <br />
                <a href="" className="footer_link footer_text">
                  {" "}
                  Electrician Services
                </a>{" "}
                <br />
                <a href="" className="footer_link footer_text">
                  {" "}
                  Cleaning Services
                </a>{" "}
                <br />
                <a href="" className="footer_link footer_text">
                  {" "}
                  Appliances Repair & Maintenance
                </a>{" "}
                <br />
              </div>
            </div>
            <div className="col-md-4 d-flex footer_col">
              <div>
                <div className="footer_heading">About</div>
                <Link to="/aboutus" className="footer_link footer_text">
                  {" "}
                  About Us
                </Link>{" "}
                <br />
                <Link to="/privacy_policy" className="footer_link footer_text">
                  {" "}
                  Privacy Policy
                </Link>{" "}
                <br />
                <Link to="/terms_conditions" className="footer_link footer_text">
                  {" "}
                  Terms & Conditions
                </Link>{" "}
                <br />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="footer_heading">Cities</div>
              <div className="d-flex align-items-center mb-2">
                <i className="fa-solid fa-location-dot footer_icon"></i>
                <div className="footer_text">
                  <span className="m-2">Mumbai</span> | <span className="m-2">Thane</span> | <span className="m-2">Navi Mumbai</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="footer_heading">Follow Us</div>
              <div className="d-flex mt-3">
                <a href="https://www.instagram.com/caremasterservices/">
                  <img src={insta} alt="Instagram" className="social_icon" style={{ marginLeft: '1px' }} />
                </a>
                <a href="https://wa.me/+918369441674">
                  <img src={whatapp} alt="Whatsapp" className="social_icon" />
                </a>
                <a href="https://www.facebook.com/CareMasterServices">
                  <img src={fb} alt="Facebook" className="social_icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="footer_hr" />
        <div className="container footer-cities">
          <div className="row mb-3" style={{ marginTop: '3em' }}>
            <div className="col-md-4">
              <div>
                <div className="footer_text">Carpenter Services</div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="footer_text ">
                <span> <a href="#" className="footer_link footer_text  mx-1">  Drill & Hanging </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Table fitting </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Storage Hinge installation</a> </span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Mirror Installation</a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Wooden chair repair </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Towel holder installation</a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Drawer replacement </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Door lock installtion</a></span>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <div>
                <div className="footer_text">Appliances Repair</div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="footer_text ">
                <span> <a href="#" className="footer_link footer_text  mx-1"> Switch Board and socket installation </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1"> Switch Board installation </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1"> Ac Switch Installation</a> </span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1"> Tubelight/Lamp installation </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Door bell installation  </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Fan Repair </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Fan Replacement </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Conceal Wiring </a></span>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <div>
                <div className="footer_text">Plumbing Services</div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="footer_text ">
                <span> <a href="#" className="footer_link footer_text  mx-1">  Bath fitting and installation  </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Hot and Cold Mixer installation </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Washbasin repair and installation </a> </span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Shower Installation</a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Waste bin repair </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Pipe leak fitting </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Sink installation  </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Flush tank installation </a></span>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <div>
                <div className="footer_text">AC Repair Services</div>
              </div>
            </div>
            <div className="col-md-8 text-left">
              <div className="footer_text ">
                <span> <a href="#" className="footer_link footer_text  mx-1">  Split AC service </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Window AC service </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Cooling problems </a> </span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Gas Filling </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Split AC installation </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Window AC Uninstallation </a></span>  |
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <div>
                <div className="footer_text">Pest Control</div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="footer_text ">
                <span> <a href="#" className="footer_link footer_text  mx-1">  General pest control </a></span>   |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Termite control </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Kitchen + Bathroom  </a> </span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  1BHK Pest Control </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  2BHK Pest Control </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  3BHK Pest Control </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Furniture bugs control </a></span>  |
                <span> <a href="#" className="footer_link footer_text  mx-1">  Bed bug control </a></span>
              </div>
            </div>
          </div>
        </div>
        <hr className="footer_hr" />
        <div className="container">
          <div className="row text-center">
            <div className="bottom_footer">
              Copyright © {new Date().getFullYear()}{" "}
              <a href="https://www.caremaster.in/">
                Caremaster India PVT LTD. (India)
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
