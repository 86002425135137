import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../config/constants";


function CancelOrderModal(props) {
  //   console.log(props.purchase_id);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [close, setClose] = useState("");

  let user_id = localStorage.getItem("key19");

  // console.log(props);
  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.6)",
  };

  const cancelReason = async () => {
    if (!reason && !otherReason) {
      toast.error("Select Your Reason ", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else {
      const formData = new FormData();
      // formData.append("user_id", Base64.decode(user_id));
      formData.append("purchase_id", props.purchase_id);
      {
        !otherReason
          ? formData.append("cancel_reason", reason)
          : formData.append("cancel_description", otherReason);
      }
      // formData.append("cancel_reason", reason);
      //append the values with key, value pair

      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      const url = `${BASE_URL}/Api/cancelorder`;

      try {
        ;
        // setLoading(true);

        const data = await axios
          .post(url, formData)
          .then((response) => {
            console.log(response);
            // window.location.reload(false);
            if (response.data.status === false) {
              toast.error("Something went wrong ", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            else {
              toast.warning("Order is Cancelled Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              props.loadCartdata();
              closeModal();
            }


            //   window.location.reload(false);
          })
          .catch((error) => {
            console.log(error);
          });
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
      }
    }

  };

  function closeModal() {
    setClose(props.hide);
  }

  return (
    <div>
      <div class="modal" show fade style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {" "}
                Why Do You Want To Cancel Your Order ?
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              {/* <p>Why Do You Want To Cancel Your Order ?</p> */}
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="Concerned about the service hygiene"
                  value="Concerned about the service hygiene"
                  onChange={() =>
                    setReason("Concerned about the service hygiene")
                  }
                />
                &nbsp;&nbsp;
                <label class="" for="Concerned about the service hygiene">
                  Concerned about the service hygiene
                </label>
              </div>
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="Hired Someone else outside"
                  value="Hired Someone else outside"
                  onChange={() => setReason("Hired Someone else outside")}
                />
                &nbsp;&nbsp;
                <label class="" for="Hired Someone else outside">
                  Hired Someone else outside
                </label>
              </div>
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="Service not required at booked time"
                  onChange={() =>
                    setReason("Service not required at booked time")
                  }
                />
                &nbsp;&nbsp;
                <label class="" for="Service not required at booked time">
                  Service not required at booked time
                </label>
              </div>
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="Booked service by mistake"
                  onChange={() => setReason("Booked service by mistake")}
                />
                &nbsp;&nbsp;
                <label class="" for="Booked service by mistake">
                  Booked service by mistake
                </label>
              </div>
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="My society/building has retricted entry for outside"
                  onChange={() =>
                    setReason(
                      "My society/building has retricted entry for outside"
                    )
                  }
                />
                &nbsp;&nbsp;
                <label
                  class=""
                  for="My society/building has retricted entry for outside"
                >
                  My society has retricted entry for outside
                </label>
              </div>
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="Vendor asking for more amount than billed"
                  onChange={() =>
                    setReason("Vendor asking for more amount than billed")
                  }
                />
                &nbsp;&nbsp;
                <label class="" for="Vendor asking for more amount than billed">
                  Vendor asking for more amount than billed
                </label>
              </div>
              <div class="">
                <input
                  class=""
                  type="radio"
                  name="name"
                  id="Other Reason"
                  onChange={() => setReason("Other Reason")}
                />
                &nbsp;&nbsp;
                <label class="" for="Other Reason">
                  Other Reason
                </label>
                {reason === "Other Reason" && (
                  <>
                    <div className="input-group mt-3">
                      <input
                        type="text"
                        className="form-control mb-2"
                        id="login_input"
                        autoComplete="off"
                        placeholder="Please Enter Your Reason"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                      />
                    </div>
                  </>
                )}
                {/* <div className="text-center">
                  <button
                    type="submit"
                    className="btn login_btn mt-2"
                    onClick={cancelReason}
                  >
                    Submit
                  </button>
                </div> */}
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button class="btn buttons mx-2 sitebtn2" onClick={props.hide}>
                  Cancel
                </button>
                {!loading && (
                  <button type="submit" class="btn buttons sitebtn2" onClick={cancelReason}>
                    Submit
                  </button>
                )}
                {loading && (
                  <button className="btn buttons sitebtn2 " disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Submitting...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelOrderModal;
