import React from 'react'
import user1 from '../../images/user 01.png'
import user2 from '../../images/user 02.png'
import user3 from '../../images/user 03.png'
import user4 from '../../images/user 04.png'
import user5 from '../../images/user 05.png'
import user6 from '../../images/user 06.png'
import asset05 from '../../images/Care Master.png'
import ps from '../../images/user 066.png'
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Testimonial() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div id="testimonial">
                    <div className="container pt-5">
                        {/* <div className="heading mt-5 mb-3 display-4 pb-5" style={{ color : '#44A726', fontWeight:'500'}}>Customer Testimonial</div> */}
                        {/* <div className="sub_heading lh-3">Here's what our customers say about us.</div> */}
                        <div className='text-center mb-5'>
                            <h2>Our happy customer's</h2>
                            <sub>We strive to do the best for our customers and here are some kind words by our customers. These kind words keeps us motivated to more.</sub>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-lg-4 col-sm-auto mt-4">
                                <motion.div whileHover={{ scale: [null, 1.1, 1.1] }}  >
                                    <div className="testimonial_card">
                                        <div className="p-2">
                                            <div className="testmonial_img_sec">
                                                <LazyLoadImage src={user1} className='testmonial_img'></LazyLoadImage>
                                            </div>
                                            <div className="user_name">Pooja Sharma</div>
                                        </div>
                                        <div className="padding_sec">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                            </div>
                                            <div className="testimonial_text">What a great team! They were able to fix what we needed fast!
                                                Amazing electrician with a job well done! I would highly recommend them!</div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <motion.div whileHover={{ scale: [null, 1.1, 1.1] }}  >
                                    <div className="testimonial_card">
                                        <div className="p-2">
                                            <div className="testmonial_img_sec">
                                                <LazyLoadImage src={user2} className='testmonial_img'></LazyLoadImage>
                                            </div>
                                            <div className="user_name">Chaitesh Pagare</div>
                                        </div>
                                        <div className="padding_sec">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                            </div>
                                            <div className="testimonial_text">These guys are always responsive and professional and I have
                                                been VERY happy with their customer service. I highly recommend their services.</div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <motion.div whileHover={{ scale: [null, 1.1, 1.1] }}  >
                                    <div className="testimonial_card">
                                        <div className="p-2">
                                            <div className="testmonial_img_sec">
                                                <LazyLoadImage src={user5} className='testmonial_img'></LazyLoadImage>
                                            </div>
                                            <div className="user_name">Sandeep Kumar</div>
                                        </div>
                                        <div className="padding_sec">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                            </div>
                                            <div className="testimonial_text">We were impressed with the skill and knowledge of the
                                                technician. They respected our home and kept it clean and neat.</div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <motion.div whileHover={{ scale: [null, 1.1, 1.1] }}  >
                                    <div className="testimonial_card">
                                        <div className="p-2">
                                            <div className="testmonial_img_sec">
                                                <LazyLoadImage src={user4} className='testmonial_img'></LazyLoadImage>
                                            </div>
                                            <div className="user_name">Ramesh Singhi</div>
                                        </div>
                                        <div className="padding_sec">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                            </div>
                                            <div className="testimonial_text">Quick turnaround, great customer service! Have used them for
                                                our office for the last 4-5 jobs we've needed, and they've always been excellent!</div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <motion.div whileHover={{ scale: [null, 1.1, 1.1] }}  >
                                    <div className="testimonial_card">
                                        <div className="p-2">
                                            <div className="testmonial_img_sec">
                                                <LazyLoadImage src={user5} className='testmonial_img'></LazyLoadImage>
                                            </div>
                                            <div className="user_name">Rajesh Pradhan</div>
                                        </div>
                                        <div className="padding_sec">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                            </div>
                                            <div className="testimonial_text">The team handled my request with utmost professionalism and
                                                ensured the service was done on time. I'm glad the engineer came before time and
                                                followed all safety protocols.</div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <motion.div whileHover={{ scale: [null, 1.1, 1.1] }}  >
                                    <div className="testimonial_card">
                                        <div className="p-2">
                                            <div className="testmonial_img_sec">
                                                <LazyLoadImage src={user6} className='testmonial_img'></LazyLoadImage>
                                            </div>
                                            <div className="user_name">Sanjeev Mishra</div>
                                        </div>
                                        <div className="padding_sec">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                                <i className="fa-solid fa-star testimonial_icon"></i>
                                            </div>
                                            <div className="testimonial_text">Good service. Your technician came with all tools and followed
                                                safety norms. He was polite and professional.</div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                        {/* <div className="row d-flex" id="hide_show"> */}

                        {/* </div> */}
                        {/* <div style={{ marginBottom:'5rem'}}>
                    <div className="load" id="load_1">Show More...</div>
                    <div className="load" id="load_2">Show Less...</div>
                </div> */}
                    </div>
                </div>
                <div id="app">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-4 offset-md-2 ">
                                <div className="app_heading">Download our App</div>
                                <div className="app_text">Our services are also available on app. <br /> You can download our free app from Google Play store.
                                </div>
                                <a href="https://play.google.com/store/apps/details?id=com.user.caremaster" className="app_link">
                                    <LazyLoadImage src={ps} alt="download care master app" className='download_img' ></LazyLoadImage>
                                    {/* <img src={ps} alt="download" className="download_img"/> */}
                                </a>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                                <LazyLoadImage src={asset05} alt="app image logo" className='app_img' ></LazyLoadImage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial