import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { BASE_URL } from "../../../../config/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../images/logo.png";
import BackButton from "../Header/BackButton";
import ServicesCategoriesCommon from "../../ServiceCategories/ServicesCategoriesCommon";
import { useDispatch, useSelector } from "react-redux";
import { addToCart as sAddToCart, removeFromCart as sRemoveFromCart } from "../../ApplicationState/CartReducer";

function SearchPageLocal() {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const [serviceValues, setServiceValues] = useState([]);
  const [nullMessage, setNullMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [itemInCartCategory, setItemInCartCategory] = useState("");

  const params = useParams();

  const getSingleProductDataByID = useCallback(async (name) => {
    name = name.replace(/%20/g, " ");
    name = name.replace(/&/g, "%26");
    name = name.replace(/%20%20%20/g, " ");
    setPageLoading(true);
    try {
      const apiResponse = await Promise.all([
        axios.get(`${BASE_URL}/Api/search?search=${name}`),
      ]);

      if (apiResponse[0].data.status === false) {
      } else {
        const categoryItemList = apiResponse[0].data.result;
        if (cartItems.length > 0) {
          setItemInCartCategory(cartItems[0].category?.trim());
        }
        setServiceValues(categoryItemList);
        categoryItemList.forEach((menuItem, index) => {
          cartItems.forEach((cartItem) => {
            categoryItemList[index].disabledByCategory = true;
            if (cartItem && menuItem.id === cartItem.id) {
              console.log(cartItem, 'cart item');
              categoryItemList[index].isCartList = true;
              categoryItemList[index].quantity = cartItem.quantity;
              categoryItemList[index].disabledByCategory = cartItem.category != menuItem.category;
            }
          });
        });
        console.log('this is', categoryItemList);
      }
    } catch (error) {
      console.log(error);
      setPageLoading(false);
    }
  });

  const addToCart = async (item) => {
    item.fromCart = false;
    dispatch(sAddToCart(item));
    return;
  };

  const removeFromCart = async (item) => {
    item.fromCart = false;
    dispatch(sRemoveFromCart(item));
    return;
  };

  useEffect(() => {
    getSingleProductDataByID(params.search_text);
  }, [cartItems, params]);


  return (
    <div className="site-page">
      <div className="cate_head">Travel & Visit Fee &#x20B9; 99</div>
      <BackButton></BackButton>
      <>
        {pageLoading && (
          <>
            <Loader />
          </>
        )}

        {nullMessage && (
          <>
            <div className="container mb-4">
              <div className="row">
                <div className="px-4 p-1 text-center">
                  <div className="display-4">
                    Coming soon...
                  </div>
                </div>
              </div>
            </div>
            <ServicesCategoriesCommon></ServicesCategoriesCommon>
          </>
        )}
        <div className="container mb-4">
          <div className="mt-3 mb-3"><h6>Note : Please note, you can add items from only one category at a time.</h6></div>
          <div className="row">
            {serviceValues &&
              serviceValues.map((items, i) => {
                return (
                  <div className="col-md-6" key={i}>
                    <div className="card px-4 cate_card">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <LazyLoadImage loading="true"
                            effect="blur"
                            src={`${BASE_URL}/uploads/${items.photo}`}
                            alt={items.name}
                            key={'item-cart-image' + i}
                            placeholderSrc={logo}
                            width={'80px'}
                            height={'80px'}
                          />
                        </div>
                        <div className="card-body">
                          <div className="card-title text-uppercase">
                            {items.name}
                          </div>
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-8">
                              <h4 className="px-4">
                                &#x20B9;{items.price}/-
                              </h4>
                            </div>
                            <div className="col-4 d-flex justify-content-center">
                              <input
                                type="button"
                                value="-"
                                className="qtyminus minus qty-search"
                                field="quantity"
                                onClick={() =>
                                  removeFromCart(items)
                                }
                                disabled={(isDisable || items.disabledByCategory) ? true : false}
                              />
                              <input
                                type="text"
                                name="quantity"
                                value={items.quantity ? items.quantity : 0}
                                className="qty qty-search"
                                disabled={(isDisable || items.disabledByCategory) ? true : false}
                              />
                              <input
                                type="button"
                                value="+"
                                className="qtyplus plus qty-search"
                                field="quantity"
                                onClick={() =>
                                  addToCart(items)
                                }
                                disabled={(isDisable || items.disabledByCategory) ? true : false}
                              />
                            </div>
                          </div>
                          <div
                            className="card-text mb-3 mt-3"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: items.description,
                            }}
                            title={(items.description)}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    </div>
  );
}

export default SearchPageLocal;
