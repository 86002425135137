import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ margin = 'yes' }) {
  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const goHome = () => {
    navigate('/');
  }
  return (
    <div className="container mt-10 mb-3" style={margin == 'yes' ? { marginTop: '70px' } : {}}>
      <div className="row">
        <div className="col-6 text-start">
          <button className="btn btn-sm prevBtn" onClick={goHome}>Go Home</button>
        </div>
        <div className="col-6 text-end">
          <button className="btn btn-sm prevBtn" onClick={goBack}>Go Back</button>
        </div>
      </div>
    </div >
  );
}

export default BackButton;
