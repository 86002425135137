import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import loginimg from "../images/login.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";
import { BASE_URL } from "../../../config/constants";
import { AppSettingsContext } from "../Context/AppSettingsProvider";
import { useSelector, useDispatch } from "react-redux";
import { clearCartByUserServer, loadServerCart } from '../../user/ApplicationState/CartReducer';

function LoginSidebar({ setLoginModal, loginModal }) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  const { setIsUserLogged, setCartUpdated } = useContext(AppSettingsContext);

  let user_id = localStorage.getItem("key1");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpbuttonLoading, setOtpButtonLoading] = useState(false);

  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [oTPError, setOTPError] = useState(false);
  const [openLogin, setOpenLogin] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [disableMobileNumber, setDisableMobileNumberError] = useState(false);
  const [profilePage, setProfilePage] = useState(false);
  const [signInPage, setSignInPage] = useState(true);

  const [resendTimer, setResendTimer] = useState(0);
  const resetIntervalTime = 120;


  // console.log(mobileNumber);

  const navigate = useNavigate();

  const onKeyPressEvent = (event) => {
    if (event.key === "Enter") {
      login();
    }
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  const onKeyPressEvent2 = (event) => {
    if (event.key === "Enter") {
      verifyotp();
    }
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const otpTimerFunction = (event) => {
    let begin = resetIntervalTime;
    var intervalCounter = setInterval(function () {
      if (begin > 0) {
        begin = begin - 1;
      } else {
        clearInterval(intervalCounter);
        return;
      }
      setResendTimer(begin);
    }, 1000);
  }

  //------ Login CODE --------//
  const login = async (e) => {
    // e.preventDefault();
    // console.log("run");
    if (!mobileNumber) {
      toast.error("Please Enter Your Mobile Number", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);
      formData.append("device_token", "");

      const url = `${BASE_URL}/Api/signup`;
      try {
        setButtonLoading(true);
        const data = await axios
          .post(url, formData)
          .then((response) => {
            console.log(response);
            if (response.data.status === false) {
              setButtonLoading(false);
              setMobileNumberError(true);
              setOtpInput(false);
            } else {
              setButtonLoading(false);
              otpTimerFunction();
              toast.success("OTP Send Successfully !!!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setOtpInput(true);
              setDisableMobileNumberError(true);
              setMobileNumberError(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setButtonLoading(false);
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setOtpInput(false);
            setDisableMobileNumberError(false);
          });
      } catch (error) {
        setButtonLoading(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOtpInput(false);
        setDisableMobileNumberError(false);
      }
    }
  };

  //------ Verify OTP CODE --------//
  const verifyotp = async (e) => {
    // e.preventDefault();
    // console.log("run");
    if (!otp) {
      toast.error("Please Enter OTP", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);
      formData.append("otp", otp);
      formData.append("device_token", "");

      const url = `${BASE_URL}/Api/verifyotp`;
      try {
        setOtpButtonLoading(true);
        const data = await axios
          .post(url, formData)
          .then(async (response) => {
            console.log(response.data.Profile);
            if (response.data.status === false) {
              setOtpButtonLoading(false);
              setOTPError(true);
              setOtpInput(true);
              setProfilePage(false);
              setSignInPage(true);
            }
            // else if (response.data.Profile.name === "") {
            //   toggleLogin();
            //   localStorage.setItem('key2', Base64.encode(response.data.Profile.name));
            //   setOtpButtonLoading(false);
            //   setProfilePage(true);
            //   setSignInPage(false);
            // }
            else {
              setIsUserLogged(true);
              localStorage.setItem(
                "key1",
                Base64.encode(response.data.Profile.id)
              );
              if (response.data.Profile.name === "") {
                localStorage.setItem(
                  "key2",
                  Base64.encode(response.data.Profile.mobile)
                );
                localStorage.setItem(
                  "key5",
                  Base64.encode(response.data.Profile.mobile)
                );
              } else {
                localStorage.setItem(
                  "key2",
                  Base64.encode(response.data.Profile.name)
                );
                localStorage.setItem(
                  "key5",
                  Base64.encode(response.data.Profile.email)
                );
              }
              localStorage.setItem(
                "key3",
                Base64.encode(response.data.Profile.mobile)
              );

              let parsedCart = cartItems;
              // let parsedCart = JSON.parse(cart);
              if (parsedCart.length > 0) {
                await clearCartByUserServer();
                let addToCartPromises = [];
                parsedCart.forEach((item, i) => {
                  addToCart(item, response.data.Profile.id);
                });
                navigate('/home/mycart');
              } else {
                dispatch(loadServerCart());
                if (cartItems.length > 1) {
                  navigate('/home/mycart');
                } else {
                  navigate('/');
                }
              }
              toggleLogin();

              toast.success("Logged in!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            setOtpButtonLoading(false);
            setProfilePage(false);
            setSignInPage(true);

            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } catch (error) {
        setOtpButtonLoading(false);
        setProfilePage(false);
        setSignInPage(true);

        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  //------ Verify OTP CODE --------//
  const resendotp = async (e) => {
    // e.preventDefault();
    // console.log("run");
    if (!mobileNumber) {
      toast.error("Please Enter Mobile Number", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("mobile", mobileNumber);

      const url = `${BASE_URL}/Api/resend`;
      try {
        // setOtpButtonLoading(true);
        const data = await axios
          .post(url, formData)
          .then((response) => {
            console.log(response);
            if (response.data.status === false) {
              // setOtpButtonLoading(false);
              setOTPError(true);
              setOtpInput(true);
              setProfilePage(false);
            } else {
              // setOtpButtonLoading(false);
              otpTimerFunction();
              setProfilePage(false);
              toast.success("OTP Resend Successfully", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            // setOtpButtonLoading(false);
            setProfilePage(false);

            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } catch (error) {
        // setOtpButtonLoading(false);
        setProfilePage(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const addToCart = async (item, user_id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("service_id", item.id);
    formData.append("qty", item.quantity);
    const url = `${BASE_URL}/Api/addtocart`;
    const data = await axios
      .post(url, formData)
      .then((response) => {
        if (response.data.status === true) {
          localStorage.removeItem("cart");
        } else {
          toast.success("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const toggleLogin = () => {
    setLoginModal(!loginModal);
  }

  return (
    <div>
      <div
        className={"modal fade" + (loginModal ? " show d-block fixed-left" : " d-none")}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ backgroundColor: "rgba(0,0,0,0.8)", }}
      >
        <div className="modal-dialog modal-dialog-aside" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "0px" }}>
              <h5 className="modal-title"></h5>
              <button
                type="button"
                className="btn loginCloseBtn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggleLogin}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center mb-4">
                    <div className="mb-4"><img src="/static/media/LOG.fe02327df17433e310bd.png" alt="Logo" className="logo_img" /></div>
                    <h3>  Login | Sign up  </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-group text-center">
                        <div
                          className={
                            mobileNumberError
                              ? " mb-3 error"
                              : " mb-3 "
                          }
                        >
                          <input
                            type="text"
                            className="form-control loginInput"
                            minLength="10"
                            maxLength="10"
                            placeholder="Enter phone Number"
                            onKeyPress={onKeyPressEvent}
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            disabled={disableMobileNumber === true ? true : false}
                            id="mobile"
                          />
                          {mobileNumberError && (
                            <div className="error-message mt-2">
                              Mobile Number is Invalid !!!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group text-center">
                        {otpInput && (
                          <>
                            <div
                              className={
                                oTPError
                                  ? "mb-3 error"
                                  : "mb-3 "
                              }
                            >
                              <input
                                type="text"
                                className="form-control loginInput font-weight-bold"
                                id="number"
                                placeholder="Enter OTP"
                                minLength="4"
                                maxLength="4"
                                onKeyPress={onKeyPressEvent2}
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                              />
                              {oTPError && (
                                <div className="error-message mt-2">
                                  OTP is Invalid !!!
                                </div>
                              )}
                            </div>
                            <div className="text-center mt-4">
                              {!otpbuttonLoading && (
                                <div className="text-center mb-2">
                                  <button
                                    className="btn loginbtn"
                                    onClick={verifyotp}
                                    type="submit"
                                  >
                                    Verify OTP
                                  </button>
                                </div>
                              )}
                              {otpbuttonLoading && (
                                <div className="text-center mb-2">
                                  <button
                                    role="button"
                                    className="btn buttons"
                                    disabled="true"
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    Verifying...
                                  </button>
                                </div>
                              )}
                              {resendTimer > 0 ? <div className="mt-2">You can re-send otp in {resendTimer} seconds</div> : ""}
                              {resendTimer == 0 ? <a className="res_otp mt-2" role="button" onClick={resendotp}> Resend OTP </a> : ""}
                            </div>
                          </>
                        )}
                        {!buttonLoading && (
                          <div className="text-center">
                            <button
                              className="btn loginbtn"
                              onClick={login}
                              type="submit"
                              style={{ display: otpInput ? "none" : "inline" }}
                              disabled={mobileNumber.length <= 0 ? true : false}
                            >
                              Continue
                            </button>
                          </div>
                        )}
                        {buttonLoading && (
                          <div className="text-center">
                            <button role="button" className="btn buttons" disabled={true}>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Sending...
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center text-sm mt-2">
                    <p>By continuing, you agree to our
                      <span>
                        <NavLink className="nav-link termsLink" to="/terms_conditions" target="_blank" >
                          Terms & Conditions
                        </NavLink>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="container mt-5">
                <div className="row">
                  <div className="col-12">
                    <h5 className="text-center" style = {{ color: "rgb(68, 167, 38)" }}>New offers | Coupon Code</h5>
                    <div
                      id="carouselExampleControls"
                      className="carousel slide carousel-fade"
                      data-bs-ride="carousel"
                      data-bs-interval="1000"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={banner01} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                          <img src={banner02} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item active">
                          <img src={banner03} className="d-block w-100" alt="..." />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="modal-footer d-inline text-center">
              <div className="row">
                <div className="col-12">
                  <div className="font-weight-light">Caremaster India PVT LTD &copy; 2023</div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* modal-bialog .// */}
      </div>{" "}
      {/* modal.// */}
    </div>
  );
}

export default LoginSidebar;
