import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Home/Header/Header'
import asset80 from '../images/Group 749.png'
import asset07 from '../images/asset 07.png'
import asset06 from '../images/asset 06.svg'
import Header2 from '../Home/Header/Header2'



function About() {
    return (
        <div>
            <div id="about" className='marg_top'>
                <div class="container">
                    <div class="">
                        <div class="row align-items-center p-3">
                            <div class="col-md-6 mt-3">
                                <img src={asset80} alt="about" class="about_img" style={{ width: '80%' }} />
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="display-5 mb-4">We are best</div>
                                <div class="about_text">
                                    Mumbai is a city that is constantly growing and evolving. As new construction projects
                                    spring up and old buildings are demolished, the need for reliable plumbing, AC repair,
                                    carpentry services & <strong>electrical consultants in Mumbai</strong> becomes more and more
                                    evident.
                                    Plumbing and <strong>ac repair Mumbai</strong> are essential for keeping homes and
                                    businesses running
                                    smoothly, while electrical and carpentry services help to ensure that new construction
                                    projects are up to code. In a city like Mumbai, where the pace of change is always high, it
                                    is important to have access to reliable and trustworthy repair ac cleaning services,
                                    electrical, and carpentry, <strong>Plumber Mumbai</strong>. Otherwise, you have to face many
                                    problems in your
                                    daily life. Many problems arise in our daily lives, such as water leakage, geyser failure,
                                    washing machine malfunction, and so on. We can solve all these problems by taking services
                                    from plumbers, air conditioning repair services, electrical services in buildings, and
                                    carpenters. They all provide their best services to us. In a city like Mumbai, all these
                                    services are available very easily. We can use their services according to our requirements.
                                    Their charges are also very reasonable. So, we should use their services when needed. We
                                    should be aware of these services, otherwise we will face many problems in the future. So,
                                    it's better to take their services on time.
                                </div>
                            </div>
                            <hr />
                            <div class="col-md-6 mt-3">
                                <div class="display-5 mb-4">15 Years of Experience</div>
                                <div class="about_text">
                                    Caremaster was incorporated on August 2nd, 2022, with domain experience of 15 years. We are
                                    one of the leading facility service providers in Mumbai, India. For 15 years, we have been
                                    providing quality electrical consultant, carpenter services, plumbing services, and AC
                                    repair services in Mumbai. Our team of qualified professionals strives to provide the best
                                    possible service to our customers and ensure that they are completely satisfied with our
                                    work. We believe that customer satisfaction is key to our success, and we always go the
                                    extra mile to ensure that our clients are happy with our services. Contact us today for all
                                    your home service needs!
                                </div>
                                <div class="about_text">
                                    We also offer a wide range of services, including plumbing, carpentry, ac maintenance
                                    services, and electrical contractors in Mumbai. We are always up-to-date with the latest
                                    techniques and use only the best tools and materials to provide you with the best possible
                                    service. We also offer a 100% satisfaction guarantee so that you can be confident that you
                                    will be happy with our work. Call us now to book an appointment!
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <img src={asset07} alt="about" class="about_img" />
                            </div>
                            <hr />
                            <div class="col-md-6 mt-3">
                                <img src={asset06} alt="about" class="about_img" style={{ width: "80%" }} />
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="display-5 mb-4">Top Quality Services</div>
                                <div class="about-text">
                                    We provide top-quality services in Mumbai at competitive rates, so you can rest assured
                                    that your needs will be taken care of. Any task, no matter how big or small, can be handled
                                    by our group of knowledgeable and competent carpenters. So whether you need to install new
                                    cabinets or repair an old dresser, we'll get the job done right. Plus, we offer a 100%
                                    satisfaction guarantee on all of our work. Call us right away, and we'll be happy to
                                    demonstrate why we're the finest in the business.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About