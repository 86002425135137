import React, { useState, useEffect } from "react";
import axios from "axios";
import { Base64 } from "js-base64";
import Loader from "../Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import EditAddress from "./EditAddress";
import DeleteAddress from "./DeleteAddress";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../config/constants";

function ChooseAddress() {
  const [addressData, setAddressData] = useState([]);
  const [nullMessage, setNullMessage] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [show, toggleShow] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [popularData, setPopularData] = useState("");
  const [variableName, setVariableName] = useState("");

  const navigate = useNavigate();
  const [tempData, setTempData] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  let user_id = localStorage.getItem("key1");
  let noti_count = localStorage.getItem("key16");

  // ------------ Add Address -------------

  const getAddressInfo = () => {
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    // const url = `http://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `${BASE_URL}/Api/address?user_id=${Base64.decode(
      user_id
    )}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        setPageLoading(false);

        if (response.data.status === false) {
          setNullMessage(true);
          setPageLoading(false);
          setAddressData("");
        } else {
          setAddressData(response.data.Data);
          setPageLoading(false);
          setNullMessage(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
        setNullMessage(true);
      });
  };
  useEffect(() => {
    getAddressInfo();
    window.scrollTo(0, 0);
    // console.log(params, "params");
  }, []);

  // ADD ADDRESS

  //   const navigate = useNavigate();

  const [pincode, setPinCode] = useState("");
  const [address, setAddress] = useState("");

  const [editModal, setEditModal] = useState(false);
  //Add Address
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const validationSchema = yup.object({
    address: yup
      .string()
      .min(1, "Please Enter Your Address")
      .required("Please Enter Your Address"),
    pincode: yup
      .string()
      .min(1, "Please Enter Your Pincode")
      .required("Please Enter Your Pincode"),
  });

  const initialValues = {
    address: "",
    pincode: "",
  };
  //Confirm Appoinment
  const onSubmit = async (values, { resetForm }) => {

    const formData = new FormData();
    formData.append("user_id", Base64.decode(user_id));

    formData.append("address", values.address);
    formData.append("pincode", values.pincode);

    const url = `${BASE_URL}/Api/add_address`;

    try {
      setButtonLoading(true);

      const data = await axios
        .post(url, formData)
        .then((response) => {
          console.log(response);
          setButtonLoading(false);
          if (response.data.status === false) {
            // navigate(-1)
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success("Address is Added Successfully ", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getAddressInfo();
            setButtonLoading(false);
            toggleShow(false);
            resetForm();
          }

          // window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });


  const checkpincode = async (value) => {
    // e.preventDefault();
    // console.log("run");
    if (value) {
      const formData = new FormData();
      formData.append("pincode", value);

      // const url = "https://192.168.1.11/vipdoor/UserApi/send_otp";
      const url = `${BASE_URL}/Api/check_pin`;
      try {

        // setButtonLoading(true);
        const data = await axios
          .post(url, formData)
          .then((response) => {
            console.log(response);
            if (response.data.status === false) {
              setButtonLoading(false);
              toast.error("Sorry, selected address is not servicable yet!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setIsDisabled(true);

            }
            else {
              toast.success("Selected adress is servicable, please click proceed to for payment.", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setIsDisabled(false);
            }

          })
          .catch((error) => {
            console.log(error);
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } catch (error) {
        // setButtonLoading(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const notificationModalData = (address_id, address, zipcode) => {
    let modalData = [address_id, address, zipcode];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };

  //Get Data for Delete
  const getDeleteData = (address_id) => {
    console.log(address_id);
    let modalData = [address_id];

    setTempData((item) => [1, ...modalData]);
    return setDeleteModal(true);
  };

  function addressAlert() {
    toast.warning("Please select an address", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const pickAddressData = (address, pincode) => {
    // console.log(address,pincode);
    localStorage.setItem("key8", Base64.encode(address));
    localStorage.setItem("key9", Base64.encode(pincode));
    setVariableName(address);
  };
  function moveToNext() {
    navigate(`/home/${user_id}/mycart/chooseaddress/date_time`);
  }

  const addressChange = () => {
    toggleShow(!show);
  }

  return (
    <div class="site-page">
      <div id="address_div" className='mt-100'>
        <div class="container">
          <div class="row bg-white">
            {pageLoading && (
              <div className="col-12">
                <Loader />
              </div>
            )}
            <div className="col-12">
              <div class="login_heading text-center mb-3">Your saved addresses</div>
              {/* <hr></hr> */}
              <h6 className="mb-4 text-center">Choose your preferred address for service</h6>
            </div>
            {nullMessage && (
              <>
                <div className="col-md-12">
                  <div class="">
                    <h6 className="text-center">Please add an address for service.</h6>
                  </div>
                </div>
              </>
            )}
            {addressData &&
              addressData.map((item) => {
                return (
                  <>
                    <div class="col-md-6 justify-content-center">
                      <label class="Plan_card1 mb-2 address_container_div">
                        <input
                          name="address"
                          class="radio"
                          type="radio"
                          // onChange={(e) => setVariableName(e.target.value)}
                          onChange={() =>
                            pickAddressData(item.address, item.pincode)
                          }
                          value={item.address_id}
                          onClick={() => checkpincode(item.pincode)}
                        />
                        <span class="plan-details1">
                          <div
                            class="address_container"
                            style={{ padding: "20px" }}
                          >
                            <div class="dropdown address_dots">
                              <div class="dropbtn p-0">
                                <i class="fa-solid fa-ellipsis-vertical" style={{ color: '#0e688d' }}></i>
                              </div>
                              <div class="dropdown-content address_dots_content">
                                <a
                                  id="edit_address"
                                  role="button"
                                  onClick={() =>
                                    notificationModalData(
                                      item.address_id,
                                      item.address,
                                      item.pincode
                                    )
                                  }
                                >
                                  Edit
                                </a>
                                <a
                                  role="button"
                                  onClick={() => getDeleteData(item.address_id)}
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                            <div class="address_text"><i class="fa-solid fa-location-dot" style={{ color: '#0e688d' }}></i>&nbsp;&nbsp;{item.address}</div>
                            <div class="address_text">{item.pincode}</div>
                          </div>
                        </span>
                      </label>
                    </div>
                  </>
                );
              })}
          </div>
          <div class="row mb-3">
            <div className="col-12">
              <div class="mt-4">
                {isDisabled ? (
                  <>
                    <button
                      class="btn buttons sitebtn2 mx-2"
                      disabled
                    >
                      Proceed
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={variableName ? moveToNext : addressAlert}
                      class="btn buttons sitebtn2 mx-2"
                    >
                      Proceed
                    </button>
                  </>
                )}
                {!show && (<button
                  type="button"
                  class="btn buttons sitebtn2"
                  id="add_btn"
                  onClick={() => addressChange()}
                >
                  Add
                </button>)}
              </div>
            </div>
          </div>
          {show && (
            <>
              <div
                class="row"
                id=""
                style={{ display: show ? "inline" : "none" }}
              >
                <h5 className="text-center">Add new address</h5>
                <div className="col-md-12 p-4" style={{ border: '1px solid #999' }}>
                  <form onSubmit={formik.handleSubmit}>
                    <div class="">
                      <div class="mb-3">
                        <label for="address" class="form-label">
                          Full Address
                        </label>
                        <textarea
                          class="form-control"
                          rows="3"
                          id="address"
                          placeholder="Enter Your Full Address"
                          name="address"
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          onChange={formik.handleChange}
                        ></textarea>
                        <p style={{ color: "red" }}>
                          {formik.touched.address && formik.errors.address
                            ? formik.errors.address
                            : ""}
                        </p>
                      </div>
                      <div class="mb-3">
                        <label for="pincode" class="form-label">
                          Pincode
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="pincode"
                          minLength="6"
                          maxLength="6"
                          placeholder="Enter Your Pincode"
                          onKeyPress={onKeyPressEvent}
                          name="pincode"
                          onBlur={formik.handleBlur}
                          value={formik.values.pincode}
                          onChange={formik.handleChange}
                        />
                        <p style={{ color: "red" }}>
                          {formik.touched.pincode && formik.errors.pincode
                            ? formik.errors.pincode
                            : ""}
                        </p>
                      </div>
                      <div class="d-flex mb-3">
                        {!buttonLoading && (
                          <div class="">
                            <button className="btn buttons sitebtn2 mx-2" type="submit">
                              Save
                            </button>
                          </div>
                        )}
                        {buttonLoading && (
                          <div class="">
                            <button
                              role="button"
                              className="btn buttons"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Saving...
                            </button>
                          </div>
                        )}
                        <button
                          type="button"
                          class="btn buttons sitebtn2"
                          id="add_btn"
                          onClick={() => toggleShow(!show)}
                        >
                          Cancel
                        </button>
                        {/* <button
                        type="submit"
                        id="close_address"
                        class="btn buttons"
                      >
                        Save
                      </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {discriptionModal === true ? (
            <EditAddress
              hide={() => setDiscriptionModal(false)}
              address_id={popularData[1]}
              address={popularData[2]}
              zipcode={popularData[3]}
              getAddressInfo={getAddressInfo}
            />
          ) : (
            ""
          )}
          {deleteModal === true ? (
            <DeleteAddress
              hide={() => setDeleteModal(false)}
              address_id={tempData[1]}
              getAddressInfo={getAddressInfo}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ChooseAddress;
