import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import Header from "../Home/Header/Header";
import axios from "axios";
import { BASE_URL } from "../../../config/constants";

function Reschedule() {
  const navigate = useNavigate();
  const params = useParams()

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];


  let pureDateObj = new Date();

  let todayDateObj = new Date(pureDateObj);
  let tomorrowDateObj = new Date(pureDateObj).setDate(todayDateObj.getDate() + 1);
  let nextTomorrowObj = new Date(pureDateObj).setDate(todayDateObj.getDate() + 2);

  let todayDate = new Date(todayDateObj).getDate();
  let tomorrowDate = new Date(tomorrowDateObj).getDate();
  let nextTomorrowDate = new Date(nextTomorrowObj).getDate();

  let todayMonthNumber = new Date(todayDateObj).toLocaleString("en-US", { month: "2-digit" });
  let tomorrowMonthNumber = new Date(tomorrowDateObj).toLocaleString("en-US", { month: "2-digit" });
  let nextTomorrowMonthNumber = new Date(nextTomorrowObj).toLocaleString("en-US", { month: "2-digit" });

  let todayDateString = todayDate + "-" + todayMonthNumber + "-" + new Date(todayDateObj).getFullYear();
  let tomorrowDateString = tomorrowDate + "-" + tomorrowMonthNumber + "-" + new Date(tomorrowDateObj).getFullYear();
  let nextTomorrowDateString = nextTomorrowDate + "-" + nextTomorrowMonthNumber + "-" + new Date(nextTomorrowObj).getFullYear();

  let todayMonth = month[new Date(todayDateObj).getMonth()];
  let tomorrowMonth = month[new Date(tomorrowDateObj).getMonth()];
  let nextTomorrowMonth = month[new Date(nextTomorrowObj).getMonth()];

  var today = new Date();

  // FOr TIME//
  const d = new Date();
  let hour = d.getHours();

  const [time, setTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(today);

  //   console.log(selectedDate);
  //   console.log(time);
  function Alert() {
    if (!selectedDate) {
      toast.error("Select Date", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (!time) {
      toast.error("Select Time", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

  }

  const notificationModalData = () => {
    // console.log(razorpay_payment_id._targetInst);
    const formData = new FormData();
    formData.append("purchase_id", params.purchase_id);
    formData.append("date", selectedDate.split("-").reverse().join("-"));
    formData.append("time", time);

    const url = `${BASE_URL}/Api/reschedule`;

    // setButtonLoading(true);
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          toast.error("Smething went wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Time and Date Reschedule Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          //    localStorage.removeItem("cartLength");
          navigate(-1);
        }

        // navigate(`/my_cart/${user_id}/thanks_to_you`);
        // window.location.reload(false);
        // navigate("/home/my_orders");
        // setButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setButtonLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div id="cart" className='marg_top'>
        <div class="container">
          <div class="row justify-content-center mt-3">
            <div class="col-md-12">
              <div class="address_container">
                <div class="row mt-3">
                  <div class="d-flex justify-content-center">
                    <label class="date_card">
                      <input
                        name="date"
                        className="radio"
                        type="radio"
                        checked={selectedDate === todayDateString ? true : false}
                        value={todayDateString}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />

                      <span className="plan-details">
                        <span className="date">{todayMonth}</span>
                        <span className="day">{todayDate}</span>
                        {/* <span className="date">{day}</span> */}
                      </span>
                    </label>
                    <label class="date_card">
                      <input
                        name="date"
                        className="radio"
                        type="radio"
                        checked={selectedDate === tomorrowDateString ? true : false}
                        value={tomorrowDateString}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />

                      <span class="plan-details">
                        <span className="date">{tomorrowMonth}</span>
                        <span className="day">{tomorrowDate}</span>
                        {/* <span class="date">{day2}</span> */}
                      </span>
                    </label>
                    <label class="date_card">
                      <input
                        name="date"
                        className="radio"
                        type="radio"
                        checked={selectedDate === nextTomorrowDateString ? true : false}
                        value={nextTomorrowDateString}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />

                      <span class="plan-details">
                        <span className="date">{nextTomorrowMonth}</span>
                        <span className="day">{nextTomorrowDate}</span>
                        {/* <span class="date">{day3}</span> */}
                      </span>
                    </label>
                  </div>
                </div>
                <div class="row mt-5">
                  {/* <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input name="time" class="radio" type="radio" disabled={hour+6 >=8 ? true: false}/>

                      <div class="text-danger extra">Extra + ₹99</div>
                      <span class="time_card">
                        <span class="time_text">08:00 AM</span>
                        <br />
                      </span>
                    </label>
                  </div> */}
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"

                        disabled={
                          today === selectedDate && hour + 6 >= 8 ? true : false
                        }
                        value="8 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <div class="text-danger extra">Extra + ₹99</div>
                      <span class="time_card">
                        <span class="time_text">08:00 AM</span>
                        <br />
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 9 ? true : false
                        }
                        value="9 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">09:00 AM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 10
                            ? true
                            : false
                        }
                        value="10 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">10:00 AM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 11
                            ? true
                            : false
                        }
                        value="11 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">11:00 AM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 12
                            ? true
                            : false
                        }
                        value="12 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">12:00 9M</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 13
                            ? true
                            : false
                        }
                        value="1 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">01:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 14
                            ? true
                            : false
                        }
                        value="2 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">02:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 15
                            ? true
                            : false
                        }
                        value="3 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">03:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 16
                            ? true
                            : false
                        }
                        value="4 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">04:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 17
                            ? true
                            : false
                        }
                        value="5 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">05:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 18
                            ? true
                            : false
                        }
                        value="6 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">06:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 19
                            ? true
                            : false
                        }
                        value="7 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span class="time_card">
                        <span class="time_text">07:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div class="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label class="date_card">
                      <input
                        name="time"
                        class="radio"
                        type="radio"
                        disabled={
                          today === selectedDate && hour + 6 >= 20
                            ? true
                            : false
                        }
                        value="8 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />
                      <div class="text-danger extra">Extra + ₹99</div>
                      <span class="time_card">
                        <span class="time_text">08:00 PM</span>
                      </span>
                    </label>
                  </div>
                </div>
                <div class="mb-3 text-end me-3">
                  <button
                    type="button"
                    class="btn buttons sitebtn2"
                    onClick={
                      selectedDate && time ? notificationModalData : Alert
                    }
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Payment Modal */}
      {/* {discriptionModal === true ? (
        <PaymentModal
          hide={() => setDiscriptionModal(false)}
          selectedDate={selectedDate}
          time={time}
        />
      ) : (
        ""
      )} */}
      <Footer />
    </div>
  );
}

export default Reschedule;
