import React from "react";
import { useNavigate } from "react-router-dom";
import ServicesCategoriesCommon from "../ServiceCategories/ServicesCategoriesCommon";

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className="mt-100 site-page">
      <div className="container">
        <div className="row">
          <div className="">
            <div id="main66">
              <div class="fof">
                <h1>Error 404</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center mt-3">
          <div className="col-md-12">
            <div className="px-4 p-1 text-center">
              <h4>Hello there, Seems like the page you are looking for isn't available!</h4>
              <h3>If you are looking for any home care services, we got you covered...!</h3>
            </div>
          </div>
          <ServicesCategoriesCommon></ServicesCategoriesCommon>
          <div className="text-center">
            <button className="btn buttons sitebtn2" onClick={() => navigate('/')}>Home</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
