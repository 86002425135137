import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { BASE_URL, UPLOAD_URL } from "../../../../config/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from '../../images/logo.png';

function Services({ categories, pageLoading }) {
  let user_id = localStorage.getItem("key1")
  return (
    <div className="container-fluid">
      <div className="row">
        <div id="services" className="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center p-3">Choose services from below categories</div>
            </div>
            <div className="row service_col p-3 justify-content-center row-cols-sm-2  row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
              {pageLoading && (<Loader />)}
              {categories &&
                categories.map((item, i) => {
                  return (
                    <div className="col-6 mb-3" key={'category-div-' + i}>
                      <Link key={'category-link' + i} to={!user_id ? `/services/${item.slug}/items` : `/services/${item.slug}`} className="service_card_link" reloadDocument="true">
                        <div className="service_card">
                          <div className="service_img_sec">
                            <LazyLoadImage loading="true"
                              effect="blur"
                              src={`${BASE_URL}${UPLOAD_URL}/${item.photo}`}
                              alt={"service"}
                              key={'category-img' + i}
                              placeholderSrc={logo}
                              className={"service_img"}
                              width={'90px'}
                              height={'90px'}
                            />
                          </div>
                          <div className="service_title">{item.name}</div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
