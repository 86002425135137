import React, { useState, useEffect } from "react";
import axios from "axios";
import NotificationModal from "./NotificationModal";
import Loader from "../Loader/Loader";
import { BASE_URL } from "../../../config/constants";
import fasticon from '../images/fast-delivery.png'
import shoppingicon from '../images/shopping.png'
import verifiedicon from '../images/check-mark.png'


function Notification() {
  let user_id = localStorage.getItem("key19");
  const [getNotifications, setGetNotifications] = useState([]);
  const [Loading, setLoading] = useState("");
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [popularData, setPopularData] = useState("");

  const getUserNotification = () => {
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `${BASE_URL}/Api/notification`;

    setLoading(true);
    axios
      .get(url, formData)
      .then((response) => {
        if (response.data.status === false) {
          setLoading(false);
          setNullMessage(true);
          setPageLoading(false);
        } else {
          setLoading(false);
          setGetNotifications(response.data.Data);
          setPageLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setPageLoading(false);
      });
  };
  useEffect(() => {
    getUserNotification();
    window.scrollTo(0, 0);
  }, []);

  const notificationModalData = (title, desc, id, photo) => {
    let modalData = [title, desc, id, photo];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };
  return (
    <div>
      <div style={{ backgroundColor: '#F8F8F3' }}>
        <div id="notification" className='marg_top' style={{ backgroundColor: '#FFF' }}>
          <div className="container-fluid p-3">
            <div className="row justify-content-center mt-3">
              <div className="col-12">
                <h1 className="mb-3 text-center">My Notifications</h1>
                <div className="text-center text-sm font-weight-light">For updates and new offers, keep checking this page</div>
              </div>
            </div>
          </div>
          {pageLoading && (
            <>
              <Loader />
            </>
          )}
          {nullMessage && (
            <>
              <div className="container-fluid marg_top">
                <div className="row justify-content-center mt-3">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <h4 className="address_container">You have no new notification! </h4>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="container pb-5" >
            <div className="row justify-content-center notification_row" >
              {getNotifications &&
                getNotifications.map((item, i) => {
                  const dateFormate = item.date.slice(0, 10);
                  return (
                    <div
                      className="col-md-12 mt-3"
                      key={i}
                      onClick={() =>
                        notificationModalData(
                          item.id,
                          item.title,
                          item.description,
                          item.photo
                        )
                      }
                    >
                      <div className="notification_container" style={{ backgroundColor: 'rgb(252, 252, 252)' }} key={'notification-container--' + i}>
                        <div className="notification_title text-uppercase" key={'notification-title-' + i}>{item.title}</div>
                        <div
                          className="notification_text"
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                          key={'not-text-' + i}
                        ></div>
                        <div className="notification_date text-end" key={'not-text-date' + i}>
                          {dateFormate.slice(0, 10).split("-").reverse().join("-")} &nbsp;
                          {item.date.slice(11, 20)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="container why-us-container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="p-2">What you can expect?</h2>
                <div className="row">
                  <div className="col-md-4">
                    <div className="d-flex justify-content-center align-items-center why-us">
                      <img src={verifiedicon} alt="" srcSet="" height="50px" width="auto" />
                      <h4 className="p-3">Verified offers</h4>
                    </div>
                    <div className="col">
                      You can trust us to provide you with the best deals and discounts from reputable brands and sellers. Browse our categories and find your verified offer today.
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-center align-items-center why-us">
                      <img src={fasticon} alt="" srcSet="" height="50px" width="auto" />
                      <h4 className="p-3">Quick & reliable Services</h4>
                    </div>
                    <div className="col">
                      We offer quick and reliable services for all kinds of needs. Whether it's home improvement or repair, we have you covered. Contact us today and get a free quote!
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-center align-items-center why-us">
                      <img src={shoppingicon} alt="" srcSet="" height="50px" width="auto" />
                      <h4 className="p-3">Offers & Coupons</h4>
                    </div>
                    <div className="col">
                      We care about your security. That's why we use the latest encryption and fraud prevention technologies to protect your payments and data.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {discriptionModal === true ? (
          <NotificationModal
            hide={() => setDiscriptionModal(false)}
            id={popularData[1]}
            title={popularData[2]}
            desc={popularData[3]}
            photo={popularData[4]}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Notification;
