import React, { useState, useEffect } from "react";
import Asset31 from "../../images/Asset 31.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../../config/constants";


function Carausel() {
  const [searchData, setSearchData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");
  let serach_text = localStorage.getItem("key_text");
  // let serach_text = localStorage.getItem("key_text");
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState("Search Service");
  const [placeholderInterval, setPlaceholderInterval] = useState(null);

  let placeholderList = ['Ac service', 'Repair service', 'Tv Repair', 'Refrigirator repair', 'ac repair'];
  // placeholderList = localStorage.getItem('services') ? JSON.parse(localStorage.getItem('services')) : [];

  let rand = 0;
  const placeholderAnimation = () => {
    setPlaceholder('Ac Repair Service');
    clearInterval(placeholderInterval);
    let placeholderanimationTimer = setInterval(() => {
      let opt = placeholderList[rand];
      if (opt) {
        setPlaceholder('Search for a service eg. ' + opt.name);
      }
      console.log('setting placeholder', rand);
      rand = Math.ceil(Math.random(placeholderList.length) * 100);
    }, 3000);
    setPlaceholderInterval(placeholderanimationTimer);
  }


  //Search To Page
  const serachPage = async () => {
    let formData = new FormData(); //formdata object

    // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
    // let url = `https://zaikamart.com/grocery/UserApi/search?search=${serach_text}`;

    let url = `${BASE_URL}/Api/search?search=${text}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    await axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response.data.success);
        setSuggestions([]);

        if (response.data.status === true && localStorage.getItem("key1")) {
          navigate(
            `/categories/${response.data.result[0].category}/${text}`
          );
        }
        else if (response.data.status === true && !localStorage.getItem("key1")) {
          navigate(
            `/categories/${response.data.result[0].category}/${text}/local`
          );
        }
        else {
          toast.error("Please Enter valid service Name", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const loadSerachData = async () => {
      const response = await axios.get(`${BASE_URL}/Api/search`);
      // console.log(response);
      setSearchData(response.data.result);
      localStorage.setItem('services', JSON.stringify(response.data.result));
    };
    loadSerachData();
    // placeholderAnimation();
  }, []);

  const onFocusHandler = () => {
    setPlaceholder('');
  }

  const onChangeHendler = (text) => {
    clearInterval(placeholderInterval);
    if (text.length < 1) {
      setSuggestions([]);
      setText("");
      placeholderAnimation();
    }
    else {
      let matches = searchData.filter((user) => {
        // console.log(user);
        const regex = new RegExp(`${text}`, "gi");
        return user.name.match(regex);
      });
      // console.log(matches, "match");
      setText(text);
      if (matches.length > 0) {
        setSuggestions(matches);
      } else {
        setSuggestions([{ "name": "No matching results!" }]);
      }
      localStorage.removeItem("key_text");
    }
  };
  const onSuggestedHandler = (text, id) => {
    setText(text);
    setSuggestions([]);
    // localStorage.setItem("key_sear", id);
    // localStorage.setItem("key_text", text);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="carau_div d-flex justify-content-center align-items-center searchDiv">
          <div className="container search_container">
            <div className="row text-center text-white">
              <div className="mb-4 serchText">
                {" "}
                Expert home care services at your doorstep
              </div>
              <div className="d-none d-md-block d-lg-block">
                <p className="text-sm lh-3 mb-5">Get your daily home care services delivered at your doorstep. Book online</p>
              </div>
              <div className="input-group search " style={{ paddingLeft: "0px" }}>
                <input
                  className="form-control search_input"
                  type="Search"
                  placeholder={placeholder}
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  onChange={(e) => onChangeHendler(e.target.value)}
                  onFocus={(e) => onFocusHandler(e.target.value)}
                  value={!serach_text ? text : serach_text}
                />

                <span
                  className="input-group-text searchButton"
                  id="basic-addon2"
                  onClick={serachPage}
                  style={{ cursor: "pointer" }}
                >
                  <img src={Asset31} className="search_img" alt="search_img" />
                </span>
              </div>
              <div className="position-relative d-block">
                <div className={suggestions.length > 0 ? "search-suggesion" : "marg_notss"}>
                  {suggestions &&
                    suggestions.map((item, i) => {
                      return (
                        <>
                          <div className="">
                            <div className="col-md-12 search-item">
                              <div
                                className="suggestte"
                                onClick={() =>
                                  onSuggestedHandler(item.name, item.product_id)
                                }
                              >
                                {item.name} {item.category ? "[" + item.category + "]" : ''}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carausel;
