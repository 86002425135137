import React, { useState, useEffect } from "react";
import axios from "axios";
import { Base64 } from "js-base64";
import { NavLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from '../images/logo.png';
import vendorCallPng from '../images/vendor-call.png';
import { BASE_URL } from '../../../config/constants';
import BackButton from "../Home/Header/BackButton";
import CancelOrderModal from "./CancelOrderModal";
import PaymentService from '../../../Services/PaymentService';

function AllOrders() {
  const navigate = useNavigate();

  const [cartData, setCartData] = useState([]);
  const [vendorData, setVendorData] = useState(null);

  const [cartPage, setCartPage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(parseFloat(5).toFixed(1));
  const [initialRating, setInitialRating] = useState("");
  const [initialStatus, setInitialStatus] = useState("");

  const [buttonLoading, setButtonLoading] = useState(false);
  const [discriptionModal, setDiscriptionModal] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [additionalCharges, setAdditionalCahrges] = useState(0);
  const [hdvCharges, setHvdCharges] = useState(0);
  const [travelCharges, setTravelCharges] = useState(99);
  const [purchaseId, setPurchaseId] = useState(null);

  let user_id = localStorage.getItem("key1");
  // let user_name = localStorage.getItem("key2");
  // let mobile = localStorage.getItem("key3");
  // let address = localStorage.getItem("key8");
  // let pincode = localStorage.getItem("key9");

  const allItemsdata = (id) => {
    let formData = new FormData(); //formdata object
    formData.append("purchase_id", id);
    formData.append("user_id", Base64.decode(user_id));

    const url = `${BASE_URL}/Api/order_details`;
    setLoading(true);
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          toast.error("No active order found!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // navigate('/');
        } else {
          setLoading(false);
          setCartPage(true);
          setCartData(response.data.Data);
          setInitialRating(response.data.Data[0].rating);
          setInitialStatus(response.data.Data[0].status);
          setPurchaseId(id);

          let totalPrice = response.data.Data[0]?.sum_total ?? 0;
          let additionalCharges = response.data.Additional_charges ?? 0;
          let hdvCharges = response.data.Data[0]?.hdv ?? 0;

          totalPrice = Number.parseFloat(totalPrice);
          additionalCharges = Number.parseFloat(additionalCharges);
          hdvCharges = Number.parseFloat(hdvCharges);

          let finalTotal = totalPrice + additionalCharges + hdvCharges + travelCharges;
          setTotalPrice(totalPrice);
          setFinalPrice(finalTotal);
          setAdditionalCahrges(additionalCharges);
          setHvdCharges(hdvCharges);

          let vendorDataTmp = response.data.Vendor;
          vendorDataTmp[0].ratings = response.data.Vendor_rating;
          console.log(vendorDataTmp, "Vendor data");
          setVendorData(vendorDataTmp[0]);
          setRating(vendorData.ratings);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const params = useParams();
  useEffect(() => {
    if (params.purchase_id) {
      allItemsdata(params.purchase_id);
    }
  }, [params]);

  const ratingChanged = async (newRating) => {
    setRating(parseFloat(newRating).toFixed(1));
  };

  const post_ratings = async (e) => {
    if (!rating) {
      toast.error("Please Select Rating", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const formData = new FormData();
      formData.append("user_id", Base64.decode(user_id));
      formData.append("purchase_id", params.purchase_id);
      formData.append("service_id", params.service_id);
      formData.append("rating", rating);
      formData.append("description", message);

      const url = `${BASE_URL}/Api/ratings`;
      try {
        setButtonLoading(true);
        const data = await axios
          .post(url, formData)
          .then((response) => {
            console.log(response);
            if (response.data.status === false) {
              setButtonLoading(false);
            } else {
              setButtonLoading(false);
              toast.success("Thank you for your rating", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              allItemsdata(params.purchase_id);
            }
          })
          .catch((error) => {
            console.log(error);
            setButtonLoading(false);
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } catch (error) {
        setButtonLoading(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const notificationModalData = (purchase_id) => {
    let modalData = [purchase_id];
    return setDiscriptionModal(true);
  };

  const payOnline = () => {
    let paymentService = new PaymentService('razorpay', finalPrice, successMessage, errorMessage);
    let paywithRazorPay = paymentService.paywithRazorPay.bind(paymentService);
    paywithRazorPay();
  }

  const confirmOrder = async (razorpay_payment_id) => {

    const formData = new FormData();
    formData.append("user_id", Base64.decode(user_id));
    formData.append("razorpay_id", razorpay_payment_id);
    formData.append("razorpay_amount", Number(finalPrice));
    formData.append('purchase_id', purchaseId);
    const url = `${BASE_URL}/Api/orderpayment`;
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Your payment is under confirmation", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ratingCommentChanged = (e) => {
    setMessage(e.target.value);
  }

  const successMessage = (e) => {
    confirmOrder(e.payment_ref);
  }


  const errorMessage = () => {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return;
  }

  return (
    <div>
      <div className="container mt-100 site-page">
        <div className="row">
          <BackButton margin="no"></BackButton>
        </div>
        {/* Top box with order details */}
        <div className="row">
          <div className="col-sm-12">
            <div className="order-box">
              <div className="">
                <h5>Order {cartData[0] ? (cartData[0]?.status == "" ? "pending" : cartData[0]?.status.toLowerCase()) : "-"}</h5>
              </div>
              <div className="">
                <div className="form-label">Order Id : {purchaseId}</div>
              </div>
              <div className="">
                <div className="form-label">Service Timings : {cartData[0]?.booking_date} {
                  new Date(
                    "1970-01-01T" + cartData[0]?.booking_time + "Z"
                  ).toLocaleTimeString("en-US", {
                    timeZone: "UTC",
                    hour12: true,
                    hour: "numeric",
                    minute: "numeric",
                  })
                }</div>
              </div>
            </div>
          </div>
        </div>
        {/* Order start code */}
        <div className="row mt-3 mb-3 m-1">
          <div className="col-sm-12 text-center job-code-bg">
            <span>Job code : {cartData[0]?.job_code}</span>
          </div>
        </div>
        {/* Serivices section */}
        <div className="row mt-3 mb-3">
          <div className="col-sm-12 ">
            <p>Service Details : </p>
          </div>
          <div>
            {cartData &&
              cartData.map((items, i) => {
                return (
                  <>
                    <div className="col-sm-6 col-md-12" key={i}>
                      <div class="card px-4 address_card">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <LazyLoadImage
                              effect="blur"
                              src={`${BASE_URL}/uploads/${items.photo}`}
                              alt={items.name}
                              key={i}
                              placeholderSrc={logo}
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <div class="card-body">
                            <h5
                              class="card-title"
                              style={{ color: "#0E688D" }}
                            >
                              {items.service_name}
                            </h5>
                            <br />
                            <div className="d-flex justify-content-between">
                              <>
                                {/* <div style={{ fontWeight: "600" }}>
                                  Price: {items.price}
                                </div> */}
                                <div style={{ fontWeight: "600" }}>
                                  Total: {items.total}
                                </div>
                                <div style={{ fontWeight: "600" }}>
                                  Qty: {items.qty}
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                        <div
                          class="card-text"
                          style={{ textAlign: "justify" }}
                          dangerouslySetInnerHTML={{
                            __html: items.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        {/* Charges Section */}
        <div className="row mb-3">
          <div className="col-sm-12">
            <div className={cartData[0]?.status.toLowerCase() == 'done' ? 'order-box paid-order-stamp' : 'order-box'}>
              {cartPage && (
                <>
                  <div class="mb-3">
                    <div className="row">
                      <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                        <div className="form-label">Service charge </div> <div>₹ {Number.parseFloat(totalPrice).toFixed(2)}</div>
                      </div>
                      <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                        <div className="form-label">Travelling & Convenience </div> <div> ₹  {Number.parseFloat(99).toFixed(2)} </div>
                      </div>
                      <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                        <div className="form-label">Additional charges </div> <div>₹ {Number.parseFloat(additionalCharges).toFixed(2)}</div>
                      </div>
                      <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                        <div className="form-label">High demand job charges </div> <div>₹ {Number.parseFloat(hdvCharges).toFixed(2)}</div>
                      </div>
                      <hr />
                      <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                        <div>Total Amount</div> <div> ₹ {Number.parseFloat(finalPrice).toFixed(2)} </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Vendor details section on order start */}
        {(vendorData && ['assigned', 'inprogress', 'ongoing'].includes(cartData[0]?.status.toLowerCase())) ?
          <div className="row mb-5">
            <div className="col-sm-12">
              <div className="order-box">
                <h4 className=""> Vendor Details </h4>
                <span className="text-uppercase">{vendorData?.name}</span> <br />
                <span className="text-uppercase">
                  <a href={"tel:" + vendorData?.mobile}>
                    <img src={vendorCallPng} className='p-2' />
                  </a>
                </span>
                <div className="d-flex justify-content-start align-items-center">
                  <ReactStars
                    count={5}
                    size={24}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                    value={Number.parseInt(vendorData.ratings)}
                    edit={false}
                  />
                  <span className="px-2"> ({vendorData.ratings})</span>
                </div>
              </div>
            </div>
          </div>
          : ""
        }
        {/* Payment section with option to pay online if COD */}
        <div className="row mb-5">
          {cartData[0]?.payment_method.toLowerCase() == 'cod' ?
            <>
              <div className="col-sm-6 text-center pt-2">
                <div className="order-box">
                  <h4> Payment Mode</h4>
                  {/* <span className="text-uppercase">{cartData[0]?.payment_method}</span> */}
                  <button className="btn">{cartData[0]?.payment_method}</button>
                </div>
              </div>
              {cartData[0]?.status.toLowerCase() == 'done' && cartData[0]?.payment_status.toLowerCase() == 'pending' ?
                <div className="col-sm-6 text-center pt-2">
                  <div className="order-box">
                    <h4>Already paid by cash?</h4>
                    <button className="btn sitebtn2" onClick={() =>
                      payOnline()
                    }>Paid by cash</button>
                  </div>
                </div>
                : <div className="col-sm-6 text-center pt-2">
                  <div className="order-box">
                    <h4>Want to pay online?</h4>
                    <button className="btn sitebtn2" onClick={() =>
                      payOnline()
                    }>Pay Now</button>
                  </div>
                </div>}

            </> :
            <div className="col-sm-12 text-center pt-2">
              <div className="order-box">
                <h4> Payment Mode</h4>
                <span className="text-uppercase">{cartData[0]?.payment_method} ({cartData[0]?.razorpay_payment_status}) </span>
              </div>
            </div>}
        </div>
        {/* Post order complete rating section */}
        {(vendorData && cartData[0]?.status.toLowerCase() == 'done' && (cartData[0].rating == "" || cartData[0].rating == null)) ?
          <div className="row mb-5">
            <div className="col-sm-12">
              <div className="order-box">
                <h4 className=""> How was your service experience with "{vendorData?.name}" ?</h4>
                <span className="text-uppercase">{vendorData?.name}</span> <br />
                <div className="d-flex justify-content-start align-items-center">
                  <ReactStars
                    count={5}
                    size={24}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                    value={Number.parseInt(rating)}
                    edit={true}
                    onChange={ratingChanged}
                  />
                  <span className="px-2"> ({rating ?? 0})</span>
                </div>
                <div>
                  <textarea rows={3} cols={30} onChange={ratingCommentChanged}></textarea>
                  <br /> <br />
                  <button type="submit" class="btn sitebtn2" onClick={post_ratings}>Submit</button>
                </div>
              </div>
            </div>
          </div>
          : ""
        }
        {/* Post order complete and rating section to show ratings given */}
        {(vendorData && cartData[0]?.status.toLowerCase() == 'done' && (cartData[0].rating != "" || cartData[0].rating != null)) ?
          <div className="row mb-5">
            <div className="col-sm-12">
              <div className="order-box">
                <h5>Your rated service : {cartData[0]?.rating} </h5>
                <span>{cartData[0]?.rating_description}</span>
              </div>
            </div>
          </div> : ""}
        {/* Bottom order cancel and reschdule section */}
        {cartData[0]?.status == "" && (
          <div className="row mb-3">
            <div className="col-md-6 d-flex justify-content-around">
              <div>
                <button className="btn sitebtn2-danger" onClick={() =>
                  notificationModalData(cartData[0]?.purchase_id)
                }>Cancel order</button>
              </div>
              <div>
                <button className="btn sitebtn2" onClick={() => {
                  navigate(`/my_orders/${cartData[0]?.purchase_id}/reschedule`);
                }}>Reschedule</button>
              </div>
            </div>
            {/* <div className="col-6 text-center">
              <button className="btn sitebtn2" onClick={() => {
                navigate(`/my_orders/${cartData[0]?.purchase_id}/reschedule`);
              }}>Reschedule</button>
            </div> */}
          </div>
        )}
        {/* Other help section */}
        <div className="row mt-5 mb-3">
          <div className="col-12 text-left">
            <NavLink
              className="nav-link"
              to="tel:+918369441674"
              role="button"
            > <i className="fas fa-phone mx-3 " aria-hidden="true"></i> Need Help?</NavLink>
          </div>
        </div>
      </div>
      {/* Modal for cancel order */}
      {
        discriptionModal && (
          <CancelOrderModal
            hide={() => setDiscriptionModal(false)}
            purchase_id={cartData[0]?.purchase_id}
            loadCartdata={() => { window.location.reload() }}
          />
        )
      }
    </div >
  );
}

export default AllOrders;
