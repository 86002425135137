import React, { useState, useEffect, useContext } from "react";
import { Base64 } from "js-base64";
import axios from "axios";
import logo from "../../user/images/logo.png";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BASE_URL } from "../../../config/constants";
import fasticon from '../images/fast-delivery.png'
import leadericon from '../images/leader.png'
import shoppingicon from '../images/shopping.png'
import verifiedicon from '../images/check-mark.png'
import ServicesCategoriesCommon from "../ServiceCategories/ServicesCategoriesCommon";
import { useSelector, useDispatch } from "react-redux";
import { addToCart as sAddToCart, removeFromCart as sRemoveFromCart, clearCart as sClearCart } from "../ApplicationState/CartReducer";

function MyCart() {
  const cartItems = useSelector((state) => state.cart.items);
  const cartAmount = useSelector((state) => state.cart.totalAmount);
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [cartData, setCartData] = useState([]);
  const [localCartData, setLoaclCartData] = useState([]);

  const [totalPrice, setTotalPrice] = useState("");
  const [coupenValue, setCoupenValue] = useState("");
  const [discount, setDiscount] = useState("");
  const [preDiscount, setPreDiscount] = useState("");

  const [totalAmountPay, setTotalAmountPay] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const [cartPage, setCartPage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  const [loginModal, setLoginModal] = useState(false);

  let user_id = localStorage.getItem("key1");
  let total_amount = localStorage.getItem("total_amount_pay");

  let localStorageData = localStorage.getItem("address_values"),
    retrivedData = JSON.parse(localStorageData);

  const loadCartdata = async (event) => {
    let parsedValues = cartItems;
    if (!user_id) {
      if (parsedValues.length <= 0) {
        setLoading(false);
        setCartPage(false);
        setNullMessage(true);
        get_category_data();
      } else {
        setLoading(false);
        setCartPage(true);
        setLoaclCartData(parsedValues);
      }

    } else {
      if (parsedValues.length <= 0) {
        setLoading(false);
        setCartPage(false);
        setNullMessage(true);
        get_category_data();
      } else {
        setLoading(false);
        setCartPage(true);
        setCartData(parsedValues);
      }
      return;
    }
  };

  const clearCart = () => {
    if (!user_id) {
      dispatch(sClearCart());
    } else {
      const url = `${BASE_URL}/Api/deletecartbyuser`;
      const formData = new FormData();
      formData.append("user_id", Base64.decode(user_id));
      axios
        .post(url, formData)
        .then((response) => {
          dispatch(sClearCart());
          setCartLoading(false);
          loadCartdata();
        })
        .catch((error) => {
          console.log(error);
          setCartLoading(false);
        });
    }

  }

  useEffect(() => {
    loadCartdata();
    setTotalAmountPay(cartAmount);
  }, [cartItems, location]);


  const cartUpdate = async (action, item, quantity) => {
    let itemCopy = { ...item };
    itemCopy.quantity = quantity;
    itemCopy.fromCart = true;
    if (action == 'add') {
      dispatch(sAddToCart(itemCopy));
    } else {
      dispatch(sRemoveFromCart(itemCopy));
    }
    return;
  };

  //Apply Copen
  const applyCopen = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("coupon_code", coupenValue); //append the values with key, value pair

    const url = "https://internadda.in/restaurant/UserApi/applycoupon";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setLoading(false);
          if (
            response.data.status === false &&
            response.data.message === "Coupon Not Exists"
          ) {
            toast.error("Coupon Not Exists", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (
            response.data.status === false &&
            response.data.message === "Coupon is expired"
          ) {
            toast.error("Coupon is expired", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (
            response.data.status === false &&
            response.data.message === "You have already used this coupon"
          ) {
            toast.error("You have already used this coupon", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success("Coupon is Applied Successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setDiscount(response.data.coupon_discount);
            localStorage.setItem(
              "coupon_discount",
              response.data.coupon_discount
            );
            localStorage.setItem("coupon_code", response.data.coupon_code);
            localStorage.setItem(
              "total_amount_pay",
              response.data.total_amount_pay
            );

            setTotalAmountPay(response.data.total_amount_pay);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function login() {
    navigate('/home/mycart?logincheck=1');
    toast.info("Please login to place your order", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return;
  }

  const get_category_data = () => {
    let formData = new FormData(); //formdata object
    let url = `${BASE_URL}/Api/show_category`;
    axios
      .get(url, formData)
      .then((response) => {
        console.log(response);
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      <div className="site-page">
        {nullMessage && (
          <>
            <div className="container mt-100" id="">
              <div className="row justify-content-center mt-4">
                <div className="col-md-12">
                  <div className="px-4 p-1 text-center">
                    <h5>Ohh ohh.. your cart seems to be empty :( </h5>
                  </div>
                </div>
                <ServicesCategoriesCommon></ServicesCategoriesCommon>
              </div>
            </div>
            <div className="container why-us-container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="p-2">Why Choose services by us?</h2>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-center align-items-center why-us">
                        <img src={verifiedicon} alt="" srcset="" height="50px" width="auto" />
                        <h4 className="p-3">Verified professionals</h4>
                      </div>
                      <div className="col">
                        All the profesionals on the platform are verified. We follow required procedure before on-boarding any professionals.
                        your safety matter us most.
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-center align-items-center why-us">
                        <img src={leadericon} alt="" srcset="" height="50px" width="auto" />
                        <h4 className="p-3">Expert professionals</h4>
                      </div>
                      <div className="col">
                        Need a pro? No problem! We have verified experts for any task. Just tell us what you need and we'll match you with the best one.
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-center align-items-center why-us">
                        <img src={fasticon} alt="" srcset="" height="50px" width="auto" />
                        <h4 className="p-3">Quick & reliable Services</h4>
                      </div>
                      <div className="col">
                        We offer quick and reliable services for all kinds of needs. Whether it's home improvement or repair, we have you covered. Contact us today and get a free quote!
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-center align-items-center why-us">
                        <img src={shoppingicon} alt="" srcset="" height="50px" width="auto" />
                        <h4 className="p-3">Easy & secure payments</h4>
                      </div>
                      <div className="col">
                        We care about your security. That's why we use the latest encryption and fraud prevention technologies to protect your payments and data.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <>
          {cartPage && (
            <div className="container mt-100 mb-2">
              <div className="row mt-4">
                <div className="col-sm-12">
                  <h4 className="serviceInfoTxt text-center">Your cart summary</h4>
                  <hr />
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-12" id="">
                  <div className="table-responsive mb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          {/* <th className="font-weight-bold" scope="col" style={{ width: "3%" }}>
                            #
                          </th> */}
                          <th scope="col" className="font-weight-bold" style={{ width: "10%" }}>
                            Image
                          </th>
                          <th scope="col" style={{ width: "30%" }}>
                            Plan / Service
                          </th>
                          {/* <th scope="col" style={{ width: "35%" }}>
                            Description
                          </th> */}
                          <th scope="col" style={{ width: "20%" }} className="">
                            Price (₹)
                          </th>
                          <th
                            scope="col"
                            className="text-center"
                            style={{ width: "65%" }}
                          >
                            Quantity
                          </th>
                          {/* <th
                            scope="col"
                            style={{ width: "10%" }}
                            className="text-end"
                          >
                            Total
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {user_id && cartData &&
                          cartData.map((item, i) => {
                            return (
                              <tr className="align-middle" key={'tr' + 1}>
                                {/* <th scope="row">{i + 1}</th> */}
                                <td>
                                  {/* <img
                                    src={`${BASE_URL}/uploads/${item.photo}`}
                                    alt="img"
                                    className="service_img"
                                    key={'img' + i}
                                  /> */}
                                  <LazyLoadImage loading="true"
                                    effect="blur"
                                    className="service_img"
                                    src={`${BASE_URL}/uploads/${item.photo}`}
                                    alt={item.name}
                                    key={'img' + i}
                                    placeholderSrc={logo}
                                    width={'40px'}
                                    height={'40px'}
                                  />
                                </td>
                                <td title={item.description}>
                                  {item.name}
                                </td>
                                <td className="">{Number.parseFloat(item.price).toFixed(2)}</td>
                                <td className="text-center">
                                  <div className="">
                                    <input
                                      type="button"
                                      value="-"
                                      className="qtyminus minus"
                                      field="quantity"
                                      onClick={() =>
                                        cartUpdate(
                                          'remove',
                                          item,
                                          Number(item.quantity) - 1
                                        )
                                      }
                                      disabled={cartLoading ? true : false}
                                    />
                                    <input
                                      type="text"
                                      name="quantity"
                                      value={item.quantity}
                                      className="qty"
                                      disabled
                                    />
                                    <input
                                      type="button"
                                      value="+"
                                      className="qtyplus plus"
                                      field="quantity"
                                      onClick={() =>
                                        cartUpdate(
                                          'add',
                                          item,
                                          Number(item.quantity) + 1
                                        )
                                      }
                                      disabled={cartLoading ? true : false}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        {!user_id ? (
                          <>
                            {localCartData &&
                              localCartData.map((items, i) => {
                                return (
                                  <tr className="align-middle" key={'tr' + i}>
                                    {/* <th scope="row">{i + 1}</th> */}
                                    <td>
                                      <LazyLoadImage loading="true"
                                        effect="blur"
                                        className="service_img"
                                        src={`${BASE_URL}/uploads/${items.photo}`}
                                        alt={items.name}
                                        key={'img' + i}
                                        placeholderSrc={logo}
                                        width={'40px'}
                                        height={'40px'}
                                      />
                                    </td>
                                    <td>{items.name}</td>
                                    <td className="text-right">{Number.parseFloat(items.price).toFixed(2)}</td>
                                    <td className="text-center">
                                      <div>
                                        <input
                                          type="button"
                                          value="-"
                                          className="qtyminus minus"
                                          field="quantity"
                                          onClick={() => {
                                            if (items.quantity >= 1) {
                                              cartUpdate(
                                                'remove',
                                                items,
                                                Number(items.quantity) - 1
                                              );
                                            }
                                          }
                                          }
                                        />
                                        <input
                                          type="text"
                                          name="quantity"
                                          value={items.quantity}
                                          className="qty"
                                          disabled
                                        />
                                        <input
                                          type="button"
                                          value="+"
                                          className="qtyplus plus"
                                          field="quantity"
                                          onClick={() =>
                                            cartUpdate(
                                              'add',
                                              items,
                                              Number(items.quantity) + 1
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                    {/* <td className="text-end">
                                      <div className="remove_item">
                                        <i
                                          className="fa-solid fa-xmark"
                                          onClick={() =>
                                            removeFromCart(items.id)
                                          }
                                        ></i>
                                      </div>
                                    </td> */}
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row p-3 cartTotalRow">
                    <div className="col-6"><sub>Item Total</sub></div>
                    <div className="col-6 text-end"><sub>₹ {Number.parseFloat(totalAmountPay).toFixed(2)}</sub></div>
                    <div className="col-6"><sub>Travelling & Visit fees</sub></div>
                    <div className="col-6 text-end"><sub>₹ {Number.parseFloat(99).toFixed(2)}</sub></div>
                    <hr />
                    <div className="col-6">Grand Total</div>
                    <div className="col-6 text-end">₹ {Number.parseFloat(totalAmountPay + 99).toFixed(2)}</div>
                  </div>
                  <div className="mb-3 text-end">
                    <button onClick={clearCart} className="btn btn-primary clearCartBtn mx-2">
                      Clear cart
                    </button>
                    {!user_id ? (
                      <>
                        <button onClick={login} className="btn btn-primary checkoutBtn">
                          Checkout
                        </button>
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/home/${user_id}/mycart/chooseaddress`}
                          className="buttons btn checkoutBtn"
                        >
                          Checkout
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div >
    </>
  );
}

export default MyCart;
