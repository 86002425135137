import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { clearCart } from '../ApplicationState/CartReducer';
import { BASE_URL } from '../../../config/constants';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Base64 } from 'js-base64';


function OrderConfirmation() {

  const [orderDetails, setOrderDetails] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [additionalCharges, setAdditionalCahrges] = useState(0);
  const [hdvCharges, setHvdCharges] = useState(0);
  const [travelCharges, setTravelCharges] = useState(99);

  const [queryParameters] = useSearchParams()
  const dispatch = useDispatch();

  let userId = localStorage.getItem("key1");
  userId = userId ? Base64.decode(userId) : null;
  let orderid = null;
  orderid = queryParameters.get("orderId");


  const getSingleOrderDetails = (id) => {
    let formData = new FormData(); //formdata object
    formData.append("purchase_id", orderid);
    formData.append("user_id", userId);

    const url = `${BASE_URL}/Api/order_details`;
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          toast.error("Failed loading order", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          let orderDetails = response.data.Data;
          console.log(orderDetails);
          setOrderDetails(orderDetails);
          let totalPrice = response.data.Data[0]?.sum_total ?? 0;
          let additionalCharges = response.data.Additional_charges ?? 0;
          let hdvCharges = orderDetails[0]?.hdv ?? 0;

          totalPrice = Number.parseFloat(totalPrice);
          additionalCharges = Number.parseFloat(additionalCharges);
          hdvCharges = Number.parseFloat(hdvCharges);

          let finalTotal = totalPrice + additionalCharges + hdvCharges + travelCharges;
          setTotalPrice(totalPrice);
          setFinalPrice(finalTotal);
          setAdditionalCahrges(additionalCharges);
          setHvdCharges(hdvCharges);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    localStorage.removeItem("cartLength");
    localStorage.removeItem("key10");
    localStorage.removeItem("cart");
    getSingleOrderDetails();
    dispatch(clearCart());

  }, [])
  return (
    <div>
      <div className="container mt-100">
        <div className="row">
          <div className="col-sm-12">
            <div className="text-center mt-100">
              <div>
                <i className="fa-solid fa-check mb-2" style={{ fontSize: '40px' }}></i>
              </div>
              <h4 className='display-6'>You have successfully created your booking!</h4>
              <div className='mt-5'>Order ref :
                <Link to={`/my_orders/${orderid}/20/all_orders`} className='mx-2 text-decoration-none'> {orderid} </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="d-flex justify-content-center">
              <Link to="/" className='btn buttons sitebtn mx-2'> <i className="fa-solid fa-backward"></i>&nbsp;&nbsp;Back to home</Link>
              <Link to={`/myorders`} className='btn buttons sitebtn'>
                <i className="fa-solid fa-list-ul"></i>&nbsp;&nbsp;My Bookings
              </Link>
            </div>
          </div>
        </div>
        {/*  */}
        {orderDetails ?
          <div className='row mt-5'>
            <div className="col-sm-12 col-md-6 offset-md-3 px-4 pb-3">
              <div class="mb-3">
                <h5>Order Summary</h5>
                <div className="row">
                  <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                    <div className="form-label">Additional charges </div> <div>₹ {Number.parseFloat(additionalCharges).toFixed(2)}</div>
                  </div>
                  <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                    <div className="form-label">High demand job charges </div> <div>₹ {Number.parseFloat(hdvCharges).toFixed(2)}</div>
                  </div>
                  <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                    <div className="form-label">Service charge </div> <div>₹ {Number.parseFloat(totalPrice).toFixed(2)}</div>
                  </div>
                  <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                    <div className="form-label">Travelling & Convenience </div> <div> ₹  {Number.parseFloat(99).toFixed(2)} </div>
                  </div>
                  <hr />
                  <div className="col-sm-6 col-md-12 d-flex justify-content-between">
                    <div>Total Amount</div> <div> ₹ {Number.parseFloat(finalPrice).toFixed(2)} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : ''}
      </div>
    </div>
  )
}

export default OrderConfirmation