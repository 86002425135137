import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../config/constants';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { SERVICE_CHARGE } from '../../../config/constants';

const SERVICE_CHARGE_LOCAL = Number.parseFloat(SERVICE_CHARGE) ?? 0;

const getCartInitValues = () => {
    let cartItems = JSON.parse(localStorage.getItem('ci'));
    if (cartItems == undefined || cartItems == null) {
        localStorage.setItem('ci', JSON.stringify([]));
        localStorage.setItem('ca', 0);
        localStorage.setItem('cit', 0);
        localStorage.setItem('citt', 0);
        return {
            items: [],
            totalAmount: 0,
            itemCount: 0,
            cartTotalAmount: 0,
        }
    } else {
        let totalAmount = 0;
        let totalItems = 0;
        cartItems.forEach((item) => {
            totalAmount = totalAmount + Number.parseFloat(item.price) * Number.parseInt(item.quantity);
            totalItems = totalItems + Number.parseFloat(item.quantity);
        });

        let cartTotalAmount = 0;
        if (totalAmount > 0) {
            cartTotalAmount = totalAmount + SERVICE_CHARGE_LOCAL;
        }

        localStorage.setItem('ci', JSON.stringify(cartItems));
        localStorage.setItem('ca', totalAmount);
        localStorage.setItem('cit', totalItems);
        localStorage.setItem('citt', cartTotalAmount);

        return {
            items: cartItems,
            totalAmount: totalAmount,
            itemCount: totalItems,
            cartTotalAmount: cartTotalAmount,
        }
    }
}


export const getUser = () => {

    const userId = localStorage.getItem('key1') ?? null;
    const name = localStorage.getItem('key2') ?? null;
    const email = localStorage.getItem('key5') ?? null;
    const mobile = localStorage.getItem('key3') ?? null;
    return {
        user_id: userId ? Base64.decode(userId) : null,
        user_name: name ? Base64.decode(name) : null,
        user_email: email ? Base64.decode(email) : null,
        user_mobile: mobile ? Base64.decode(mobile) : null
    }
}

const cartSlicer = createSlice({
    name: 'cart',
    initialState: getCartInitValues(),
    reducers: {
        addToCartReducer: (state, action) => {
            const newItem = action.payload;
            if (state.items.length > 0) {
                const existingItem = state.items.find((item) => item.id === newItem.id);
                if (existingItem === undefined) {
                    console.log("pushing this", existingItem);
                    state.items.push({ ...newItem, quantity: 1 });
                } else {
                    existingItem.quantity = existingItem.quantity ? (Number.parseInt(existingItem.quantity) + 1) : 1;
                }
            } else {
                state.items.push({ ...newItem, quantity: 1 });
            }

            let tmpTotal = 0;
            let cartTotalAmount = 0;
            let newCartItemsQuantity = 0;
            state.items.forEach((item) => {
                tmpTotal = tmpTotal + (Number.parseFloat(item.price) * Number.parseInt(item.quantity));
                newCartItemsQuantity = newCartItemsQuantity + (Number.parseFloat(item.quantity));
            });

            state.totalAmount = tmpTotal;
            state.itemCount = newCartItemsQuantity;


            if (tmpTotal > 0) {
                cartTotalAmount = state.totalAmount + SERVICE_CHARGE_LOCAL;
                state.cartTotalAmount = cartTotalAmount;
            }

            localStorage.setItem('ci', JSON.stringify(state.items));
            localStorage.setItem('ca', state.totalAmount);
            localStorage.setItem('cit', state.itemCount);
            localStorage.setItem('citt', state.cartTotalAmount);
            success('Item added to cart');
        },

        removeFromCartReducer: (state, action) => {
            const newItem = action.payload;
            if (state.items.length > 0) {
                const removedItem = state.items.find((item) => item.id === newItem.id);
                if (removedItem != undefined) {
                    if (removedItem.quantity > 1) {
                        removedItem.quantity = Number.parseInt(removedItem.quantity) - 1
                    } else {
                        state.items = state.items.filter((item) => item.id !== removedItem.id);
                    }
                } else {
                    state.items = state.items.filter((item) => item.id !== removedItem.id);
                }
            }

            let tmpTotal = 0;
            let cartTotalAmount = 0;
            let newCartItemsQuantity = 0;
            state.items.forEach((item) => {
                tmpTotal = tmpTotal + (Number.parseFloat(item.price) * Number.parseInt(item.quantity));
                newCartItemsQuantity = newCartItemsQuantity + (Number.parseFloat(item.quantity));
            });

            state.cartTotalAmount = tmpTotal + SERVICE_CHARGE_LOCAL;
            if (tmpTotal <= 0) {
                cartTotalAmount = tmpTotal - SERVICE_CHARGE_LOCAL;
                state.cartTotalAmount = cartTotalAmount;
            }

            state.totalAmount = tmpTotal;
            state.itemCount = newCartItemsQuantity;
            localStorage.setItem('ci', JSON.stringify(state.items));
            localStorage.setItem('ca', state.totalAmount);
            localStorage.setItem('cit', state.itemCount);
            localStorage.setItem('citt', state.cartTotalAmount);

            if (state.items.length != 0) {
                warning('Item removed from cart');
            }

        },

        clearCartReducer: (state, action) => {
            state.items = [];
            state.totalAmount = 0;
            state.itemCount = 0;
            state.cartTotalAmount = 0;
            localStorage.setItem('ci', JSON.stringify(state.items));
            localStorage.setItem('ca', state.totalAmount);
            localStorage.setItem('cit', state.itemCount);
            localStorage.setItem('citt', state.cartTotalAmount);
            warning('Cart cleared');
        },

        clearCart: (state, action) => {
            const userInfo = getUser();
            if (userInfo.user_id) {
                const itemRemoved = clearCartServer(action.payload);
                if (itemRemoved != null) {
                    cartSlicer.caseReducers.clearCartReducer(state, itemRemoved);
                }
            } else {
                cartSlicer.caseReducers.clearCartReducer(state, action)
            }
        },

        clearServcerCart: (state, action) => {
            const userInfo = getUser();
            if (userInfo.user_id) {
                const itemRemoved = clearCartServer(action.payload);
            }
        }
    },
});

export const addToCart = (item) => async (dispatch, getState) => {
    if (checkItemCategory(item)) {
        const userInfo = getUser();
        if (userInfo.user_id) {
            let itemAdded = null;
            if (item.quantity > 1) {
                itemAdded = await updateCartServer(item, userInfo.user_id);
            } else {
                itemAdded = await addToCartServer(item, userInfo.user_id);
            }
            if (itemAdded != null) {
                dispatch(cartSlicer.actions.addToCartReducer(itemAdded));
            }
        } else {
            dispatch(cartSlicer.actions.addToCartReducer(item));
        }
    }
};

export const removeFromCart = (item) => async (dispatch, getState) => {
    const userInfo = getUser();
    if (checkItemCategory(item)) {
        if (userInfo.user_id) {
            let itemRemoved = null;
            if (item.quantity >= 1) {
                item.quantity = Number.parseInt(item.quantity);
                itemRemoved = await updateCartServer(item, userInfo.user_id);
            } else {
                itemRemoved = await removeFromCartServer(item, userInfo.user_id);
            }

            if (itemRemoved != null) {
                dispatch(cartSlicer.actions.removeFromCartReducer(itemRemoved));
            }
        } else {
            dispatch(cartSlicer.actions.removeFromCartReducer(item));
        }
    }
};

export const checkItemCategory = (addedItem) => {

    if (addedItem.fromCart == true) {
        return true;
    }
    let cart = localStorage.getItem('ci');
    cart = cart ? JSON.parse(cart) : [];
    let products = localStorage.getItem('services');
    products = products ? JSON.parse(products) : [];
    const itemCategory = (products.find((item) => item.id == addedItem.id)?.['category']?.trim());
    if ((cart.length > 0) && (itemCategory === undefined || (cart[0]['category'].trim() !== itemCategory))) {
        warning("Please choose items from only one category.");
        return false;
    }
    return true;
}


export const success = (message) => {
    toast.success(message ? message : "", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const error = (message) => {
    toast.error(message ? message : "", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const warning = (message) => {
    toast.warning(message ? message : "", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export const updateCart = (item) => {
    const userInfo = getUser();
    if (userInfo.user_id) {
        const itemAdded = updateCartServer(item);
        if (itemAdded != null) {
            cartSlicer.actions.addToCartReducer(itemAdded);
        }
    } else {
        cartSlicer.actions.addToCartReducer(item)
    }
}

export const addToCartServer = async (item, userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("service_id", item.id);
    formData.append("qty", item.quantity);
    const url = `${BASE_URL}/Api/addtocart`;
    return await axios
        .post(url, formData)
        .then((response) => {
            if (response.data.status === true) {
                // let itemAdded = response.data.Services?.find((inItem) => {
                //     return inItem.service_id === item.id;
                // });
                let itemAdded = response.data.Services;
                return {
                    id: itemAdded.service_id,
                    quantity: Number.parseInt(itemAdded.qty),
                    price: Number.parseFloat(itemAdded.price),
                    category: item.category?.trim(),
                    name: item.name?.trim(),
                }
            } else {
                warning('something went wrong');
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
            warning('something went wrong');
            return null;
        });
}

const removeFromCartServer = async (item, userId) => {
    console.log(item, 'to be cart');
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("service_id", item.id);
    const url = `${BASE_URL}/Api/removecart`;
    return await axios
        .post(url, formData)
        .then((response) => {
            if (response.data.status === true) {
                return {
                    id: item.id,
                    quantity: Number.parseInt(item.quantity),
                    price: Number.parseFloat(item.price),
                    category: item.category?.trim(),
                    name: item.name?.trim(),
                }
            } else {
                warning("Something went wrong");
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
            warning('something went wrong');
            return null;
        });
}

const updateCartServer = async (item, userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("service_id", item.id);
    formData.append("qty", item.quantity);

    const url = `${BASE_URL}/Api/updatecart`;

    return axios
        .post(url, formData)
        .then((response) => {
            if (response.data.status === true) {
                return {
                    id: item.id,
                    quantity: Number.parseInt(item.quantity),
                    price: Number.parseFloat(item.price),
                    category: item.category?.trim(),
                    name: item.name?.trim(),
                }
            } else {
                warning('something went wrong');
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
            warning('something went wrong');
            return null;
        });
}

const clearCartServer = async (item, userId) => {
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("service_id", item.id);
    formData.append("qty", item.quantity);

    const url = `${BASE_URL}/Api/updatecart`;

    return axios
        .post(url, formData)
        .then((response) => {
            if (response.data.status === true) {
                return {
                    id: item.id,
                    quantity: Number.parseInt(response.data.Services.qty),
                    price: Number.parseFloat(response.data.Services.price),
                }
            } else {
                warning('something went wrong');
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
            warning('something went wrong');
            return null;
        });
}

export const clearCartByUserServer = () => {
    const userInfo = getUser();
    if (userInfo.user_id) {
        const url = `${BASE_URL}/Api/deletecartbyuser`;
        const formData = new FormData();
        formData.append("user_id", userInfo.user_id);
        axios
            .post(url, formData)
            .then((response) => {
            })
            .catch((error) => {
            });
    }

}

export const loadServerCart = (item) => async (dispatch, getState) => {
    const userInfo = getUser();
    if (userInfo.user_id) {
        console.log("THIS IS USER", userInfo);
        cartSlicer.actions.clearCartReducer([]);

        axios.get(
            `${BASE_URL}/Api/cartlist?user_id=${userInfo.user_id}`
        ).then((data) => {
            console.log(data.data.Services, "THIS IS UPDTED SERVER CART");
            if (data.data.Services.length > 0) {
                data.data.Services.forEach(element => {
                    let newItem = {
                        id: element.service_id,
                        quantity: Number.parseInt(element.qty),
                        price: Number.parseFloat(element.price),
                        category: element.category?.trim(),
                        name: element.name?.trim(),
                    };
                    console.log('We will add', newItem);
                    dispatch(cartSlicer.actions.addToCartReducer(newItem));
                });
            }
        });
    }
}


export const { clearCart, initCart } = cartSlicer.actions;
export default cartSlicer.reducer;