import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PaymentModal from "./PaymentModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SERVICE_CHARGE } from "../../../config/constants";

function DateTime() {

  const navigate = useNavigate();

  const cartItems = useSelector((state) => state.cart.items);
  const cartTotal = useSelector((state) => state.cart.totalAmount);

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let pureDateObj = new Date();

  let todayDateObj = new Date(pureDateObj);
  let tomorrowDateObj = new Date(pureDateObj).setDate(todayDateObj.getDate() + 1);
  let nextTomorrowObj = new Date(pureDateObj).setDate(todayDateObj.getDate() + 2);

  let todayDate = new Date(todayDateObj).getDate();
  let tomorrowDate = new Date(tomorrowDateObj).getDate();
  let nextTomorrowDate = new Date(nextTomorrowObj).getDate();

  let todayMonthNumber = new Date(todayDateObj).toLocaleString("en-US", { month: "2-digit" });
  let tomorrowMonthNumber = new Date(tomorrowDateObj).toLocaleString("en-US", { month: "2-digit" });
  let nextTomorrowMonthNumber = new Date(nextTomorrowObj).toLocaleString("en-US", { month: "2-digit" });

  let todayDateString = todayDate + "-" + todayMonthNumber + "-" + new Date(todayDateObj).getFullYear();
  let tomorrowDateString = tomorrowDate + "-" + tomorrowMonthNumber + "-" + new Date(tomorrowDateObj).getFullYear();
  let nextTomorrowDateString = nextTomorrowDate + "-" + nextTomorrowMonthNumber + "-" + new Date(nextTomorrowObj).getFullYear();

  let todayMonth = month[new Date(todayDateObj).getMonth()];
  let tomorrowMonth = month[new Date(tomorrowDateObj).getMonth()];
  let nextTomorrowMonth = month[new Date(nextTomorrowObj).getMonth()];

  // FOr TIME//
  const d = new Date();
  let hour = d.getHours();
  const [firstdate, setFirstdate] = useState("");
  const [time, setTime] = useState("");
  const [selectedDate, setSelectedDateValue] = useState(todayDateString);
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [finalChargeAmount, setFinalChargeAmount] = useState(0);

  function setSelectedDate(value) {
    setSelectedDateValue(value);
    var ele = document.getElementsByName("time");
    for (var i = 0; i < ele.length; i++) {
      ele[i].checked = false;
    }
    setTime("");
  }


  useEffect(() => {
    if (['8 AM', '8 PM'].includes(time)) {
      toast.warn(`Extra charges applicable! Cart amount updated.`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setFinalChargeAmount(cartTotal + SERVICE_CHARGE * 2);
    } else {
      setFinalChargeAmount(cartTotal + SERVICE_CHARGE);
    }
  }, [time])

  function Alert() {
    if (!selectedDate) {
      toast.error("Select Date", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    else if (!time) {
      toast.error("Select Time", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  const notificationModalData = () => {
    return setDiscriptionModal(true);
  };


  useEffect(() => {
    if (cartItems.length <= 0) {
      navigate('/');
    }
    setFinalChargeAmount(cartTotal + SERVICE_CHARGE);
  }, []);

  return (
    <div className="site-page">
      <div id="cart" className='marg_top'>
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-md-12">
              <div className="address_container">
                <div className="row mt-3">
                  <div className="d-flex justify-content-center">
                    <label className="date_card">
                      <input
                        name="date"
                        className="radio"
                        type="radio"
                        checked={selectedDate === todayDateString ? true : false}
                        value={todayDateString}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />

                      <span className="plan-details">
                        <span className="date">{todayMonth}</span>
                        <span className="day">{todayDate}</span>
                        {/* <span className="date">{day}</span> */}
                      </span>
                    </label>
                    <label className="date_card">
                      <input
                        name="date"
                        className="radio"
                        type="radio"
                        checked={selectedDate === tomorrowDateString ? true : false}
                        value={tomorrowDateString}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />

                      <span className="plan-details">
                        <span className="date">{tomorrowMonth}</span>
                        <span className="day">{tomorrowDate}</span>
                        {/* <span className="date">{day2}</span> */}
                      </span>
                    </label>
                    <label className="date_card">
                      <input
                        name="date"
                        className="radio"
                        type="radio"
                        checked={selectedDate === nextTomorrowDateString ? true : false}
                        value={nextTomorrowDateString}
                        onChange={(e) => setSelectedDate(e.target.value)}
                      />

                      <span className="plan-details">
                        <span className="date">{nextTomorrowMonth}</span>
                        <span className="day">{nextTomorrowDate}</span>
                        {/* <span className="date">{day3}</span> */}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="row mt-5">
                  {/* <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input name="time" className="radio" type="radio" disabled={hour+6 >=8 ? true: false}/>

                      <div className="text-danger extra">Extra + ₹99</div>
                      <span className="time_card">
                        <span className="time_text">08:00 AM</span>
                        <br />
                      </span>
                    </label>
                  </div> */}
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 8 ? true : false
                        }
                        value="8 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <div className="text-danger extra">Extra + ₹99</div>
                      <span className="time_card">
                        <span className="time_text">08:00 AM</span>
                        <br />
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 9 ? true : false
                        }
                        value="9 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">09:00 AM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 10
                            ? true
                            : false
                        }
                        value="10 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">10:00 AM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 11
                            ? true
                            : false
                        }
                        value="11 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">11:00 AM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 12
                            ? true
                            : false
                        }
                        value="12 AM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">12:00 9M</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 13
                            ? true
                            : false
                        }
                        value="1 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">01:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 14
                            ? true
                            : false
                        }
                        value="2 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">02:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 15
                            ? true
                            : false
                        }
                        value="3 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">03:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 16
                            ? true
                            : false
                        }
                        value="4 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">04:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 17
                            ? true
                            : false
                        }
                        value="5 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">05:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 18
                            ? true
                            : false
                        }
                        value="6 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">06:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 19
                            ? true
                            : false
                        }
                        value="7 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />

                      <span className="time_card">
                        <span className="time_text">07:00 PM</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-4 col-xl-3 col-6 d-flex justify-content-center mb-5">
                    <label className="date_card">
                      <input
                        name="time"
                        className="radio"
                        type="radio"
                        disabled={
                          todayDateString === selectedDate && hour + 6 >= 20
                            ? true
                            : false
                        }
                        value="8 PM"
                        onChange={(e) => setTime(e.target.value)}
                      />
                      <div className="text-danger extra">Extra + ₹99</div>
                      <span className="time_card">
                        <span className="time_text">08:00 PM</span>
                      </span>
                    </label>
                  </div>
                </div>
                {
                  ['8 AM', '8 PM'].includes(time) ?
                    <div>
                      <h6 className="text-danger text-bolder text-center"> ( Please note, extra ₹ {SERVICE_CHARGE} high demand job charges applicable on final cart for selected time slot. )</h6>
                    </div>
                    : ''
                }
                <div className="mb-3 text-end me-3">
                  <button
                    type="button"
                    className="btn buttons sitebtn2"
                    onClick={
                      selectedDate && time ? notificationModalData : Alert
                    }
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row p-5 cartTotalRow">
            <div className="col-6"><sub>Item Total</sub></div>
            <div className="col-6 text-end"><sub>₹ {Number.parseFloat(cartTotal).toFixed(2)}</sub></div>
            <div className="col-6"><sub>Travelling & Visit fees</sub></div>
            <div className="col-6 text-end"><sub>₹ {Number.parseFloat(99).toFixed(2)}</sub></div>

            {['8 AM', '8 PM'].includes(time) ?
              <>
                <div className="col-6"><sub>High Demand job charges</sub></div>
                <div className="col-6 text-end"><sub>₹ {Number.parseFloat(99).toFixed(2)}</sub></div>
                <hr />
                <div className="col-6">Grand Total</div>
                <div className="col-6 text-end">₹ {Number.parseFloat(finalChargeAmount).toFixed(2)}</div>
              </>
              :
              <>
                <hr />
                <div className="col-6">Grand Total</div>
                <div className="col-6 text-end">₹ {Number.parseFloat(finalChargeAmount).toFixed(2)}</div>
              </>
            }
          </div>
        </div>
      </div>
      {/* Payment Modal */}
      {discriptionModal === true ? (
        <PaymentModal
          hide={() => setDiscriptionModal(false)}
          selectedDate={selectedDate}
          time={time}
          finalAmount={finalChargeAmount}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default DateTime;
