import React, { useState } from "react";
import axios from "axios";

import { Base64 } from "js-base64";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import pay from "../images/pay.png";
import { BASE_URL, RAZORPAY_KEY, RAZORPAY_SECRET } from "../../../config/constants";

function PaymentModal(props) {
  const date = props.selectedDate && props.selectedDate.split("-").reverse().join("-");
  const time = props.time && props.time.split("-").reverse().join("-");
  const finalChargeAmount = props.finalAmount;

  let user_id = localStorage.getItem("key1");
  let user_name = localStorage.getItem("key2");
  let mobile = localStorage.getItem("key3");

  let address = localStorage.getItem("key8");
  let pincode = localStorage.getItem("key9");

  let total_amount = localStorage.getItem("key10");
  const navigate = useNavigate();

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };

  //Payment On Delivery
  const cashOnDelivery = async (razorpay_payment_id) => {
    // console.log(razorpay_payment_id._targetInst);
    const formData = new FormData();
    formData.append("user_id", Base64.decode(user_id));

    {
      razorpay_payment_id._targetInst === null
        ? formData.append("razorpay_id", "")
        : formData.append("razorpay_id", razorpay_payment_id);
    }
    // {
    //   time === "8 AM" || time === "8 PM"
    //     ? formData.append("razorpay_amount", Number(total_amount) + 99)
    //     : formData.append("razorpay_amount", total_amount);
    // }
    formData.append("razorpay_amount", Number(finalChargeAmount));
    formData.append("user_name", Base64.decode(user_name));
    formData.append("address", Base64.decode(address));
    formData.append("mobile", Base64.decode(mobile));
    {
      razorpay_payment_id._targetInst === null
        ? formData.append("payment_method", "COD")
        : formData.append("payment_method", "Online");
    }
    formData.append("book_date", date);
    formData.append("book_time", time);
    formData.append("pincode", Base64.decode(pincode));

    const url = `${BASE_URL}/Api/orderservice`;

    // setButtonLoading(true);
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === false) {
          toast.error("Smething went wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Your Order is Placed Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          localStorage.removeItem("cartLength");
          navigate(`/home/${user_id}/mycart/chooseaddress/date_time/OrderConfirmation?orderId=${response.data.orderid}`);
        }

        // navigate(`/my_cart/${user_id}/thanks_to_you`);
        // window.location.reload(false);
        // navigate("/home/my_orders");
        // setButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setButtonLoading(false);
      });
  };

  //Payment On Delivery
  const payByRazorPay = (e) => {
    e.preventDefault();
    var options = {
      key: RAZORPAY_KEY,
      key_secret: RAZORPAY_SECRET,
      // amount: time === "8 AM" || time === "8 PM" ? Number(total_amount) + 99 * 100 : Number(total_amount) * 100,
      amount: finalChargeAmount * 100,
      currency: "INR",
      name: "Care Master ",
      description: "Care Master Service",

      handler: function (response) {
        console.log(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);

        if (response.razorpay_payment_id) {
          cashOnDelivery(response.razorpay_payment_id);
          //   navigate(`/my_cart/${user_id}/thanks_to_you`);

          // toast.success("Your Order is Placed Successfully", {
          //   position: "top-center",
          //   autoClose: 3000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
        }
      },

      prefill: {
        // name: user_name,
        // contact: mobile,
        // email: user_email,
      },
      notes: {
        // address: retrivedData[0],
      },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
    pay.on("payment.failed", function (resp) {
    });
    // window.location.reload(false);
  };

  return (
    <div>
      <div class="modal" show fade style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {" "}
                Payment Modal
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <div class="cookiesContent" id="cookiesPopup">
                {/* <button class="close">✖</button> */}
                <img src={pay} alt="cookies-img" />
                <p>
                  Please Select the Payment Type
                </p>
                <div className="d-flex justify-content-center">
                  <button class="accept mx-2" onClick={cashOnDelivery}>
                    Cash On Delivery
                  </button>
                  <button class="accept" onClick={payByRazorPay}>Online Payment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
