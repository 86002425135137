import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import axios from "axios";
import logo from "../images/logo.png";
import Header from "../Home/Header/Header";
import Footer from "../Footer/Footer";
import Header2 from "../Home/Header/Header2";
import { BASE_URL } from "../../../config/constants";

function MyOffers() {
  const [serviceValues, setServiceValues] = useState([]);

  const [nullMessage, setNullMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const getSingleProductData = () => {
    // encodeURIComponent(name);
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    const url = `${BASE_URL}/Api/offers`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response);
        setPageLoading(false);

        if (response.data.status === false) {
          setNullMessage(true);
        } else {
          setServiceValues(response.data.Data);
          setNullMessage(false);
          setPageLoading(false);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getSingleProductData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div style={{ padding: "40px 0px" }} className='mt-100'>
        <div className="container">
          <div className="row">
            {pageLoading && (
              <>
                <Loader />
              </>
            )}
            <div class="login_heading">My offers</div><hr />
            {
              false ?
                <h6 className="mb-4">List of offers specifically curated for our customers</h6>
                : ''
            }
            {nullMessage && (
              <>
                <div className="col-md-12 mt-5">
                  <div class="">
                    <h5 className="text-center">Sorry, currently there are no new offers. Keep checking this section for updates.</h5>
                  </div>
                </div>
              </>
            )}
            {serviceValues &&
              serviceValues.map((items, i) => {
                const dateFormate = items.date.slice(0, 10);
                return (
                  <>
                    <div className="col-md-6" key={i}>
                      <div class="card px-4">
                        <div className="d-flex align-items-center">
                          <div className="">
                            <LazyLoadImage
                              effect="blur"
                              src={`${BASE_URL}/uploads/${items.photo}`}
                              alt={items.name}
                              key={i}
                              placeholderSrc={logo}
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                              }}
                            />
                            {/* <img
                              src={`${BASE_URL}/uploads/${items.photo}`}
                              class=""
                              alt="..."
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                              }}
                            /> */}
                          </div>
                          <div class="card-body">
                            <h5 class="card-title" style={{ color: "#0E688D" }}>
                              {items.title}
                            </h5>
                            <div
                              class="card-text"
                              style={{ textAlign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: items.description,
                              }}
                            ></div>
                            {dateFormate.slice(0, 10).split("-").reverse().join("-")} &nbsp;
                            {items.date.slice(11, 20)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="">
                <hr />
                <h5 className="mt-5">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mt-4 d-flex align-items-center">
                      <div>
                        <p className="font-weight-light display-6">Place your orders and increase chances for getting exclusive offers.</p>
                        <img src="https://d2kh7o38xye1vj.cloudfront.net/wp-content/uploads/2021/10/banner-tracking.png" alt="" srcset="" height="auto" width={400} />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 mt-4 d-flex align-items-center justify-content-center">
                      <div>
                        <p className="font-weight-light display-6">
                          Try our our App
                        </p>
                        <a href="https://play.google.com/store/apps/details?id=com.user.caremaster" class="app_link">
                          <img src="/static/media/user 066.234bfb1a1510601e3105.png" alt="download" class="download_img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MyOffers;
