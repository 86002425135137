import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import BackButton from "../Home/Header/BackButton";
import { BASE_URL } from "../../../config/constants";
import ServicesCategoriesCommon from "./ServicesCategoriesCommon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { addToCart as sAddToCart, removeFromCart as sRemoveFromCart } from "../ApplicationState/CartReducer";


function CartLocalStorage() {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const [categoryData, setCategoryData] = useState([]);
  const [serviceValues, setServiceValues] = useState([]);
  const [nullMessage, setNullMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  let user_id = localStorage.getItem("key1");

  const params = useParams();

  const loadServices = (name) => {
    name = name.replace(/%20/g, " ");
    name = name.replace(/&/g, "%26");
    name = name.replace(/%20%20%20/g, " ");

    let formData = new FormData(); //formdata object
    const url = `${BASE_URL}/Api/services?category=${name}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    axios
      .get(url, formData, config)
      .then((response) => {
        if (response.data.status === false) {
          setNullMessage(true);
          setServiceValues([]);
          localStorage.setItem("services", JSON.stringify(response.data.Services));
        } else {
          if (cartItems) {
            let tmp = response.data.Services;
            tmp.map((item) => {
              let cartIt = cartItems.find((it) => it.id === item.id);
              if (cartIt) {
                item.quantity = cartIt.quantity;
              }
              return item;
            });
            setServiceValues(tmp);
          } else {
            setServiceValues(response.data.Services);
          }
          if (response.data.Category_data.length > 0) {
            setCategoryData(response.data.Category_data[0]);
          }
          localStorage.setItem("services", JSON.stringify(response.data.Services));
        }
      })
      .catch((error) => {
      });
  };

  const addToCart = async (item) => {
    item.fromCart = false;
    dispatch(sAddToCart(item));
    return;
  };

  const removeFromCart = async (item) => {
    item.fromCart = false;
    dispatch(sRemoveFromCart(item));
    return;
  };

  useEffect(() => {
    loadServices(params.service_name);
  }, [cartItems, params]);


  return (
    <div>
      <div className="cate_head">Travel & Visit Fee &#x20B9; 99</div>
      <BackButton></BackButton>
      <>
        {pageLoading && (
          <>
            <Loader />
          </>
        )}

        {nullMessage && (
          <>
            <div className="container mb-4">
              <div className="row">
                <div className="px-4 p-1 text-center">
                  <div className="display-4">
                    Coming soon...
                  </div>
                </div>
              </div>
            </div>
            <ServicesCategoriesCommon></ServicesCategoriesCommon>
          </>
        )}
        <div className="container mb-4">
          {categoryData.name ? <div className="row pt-2">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-8">
                  <h3 className="serviceInfoTxt">{categoryData.name} Services</h3>
                  <hr />
                  <p className="serviceInfoTxtInfo category-info">{categoryData.description_long}</p>
                </div>
                <div className="col-sm-4 d-flex justify-content-around align-items-center category-info ">
                  <LazyLoadImage loading="true"
                    src={`${BASE_URL}/uploads/${categoryData.photo}`}
                    alt={categoryData.name}
                    key={'product-image'}
                    className="category-description-logo category-info"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12">
            </div>
          </div> : ""}
          <div className="row">
            <hr className="category-info" />
            <h5 className="serviceInfoTxt category-info">Choose Services</h5>
            {serviceValues &&
              serviceValues.map((items, i) => {
                return (
                  <div className="col-md-6" key={i}>
                    <div className="card px-4 cate_card">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <LazyLoadImage loading="true"
                            effect="blur"
                            src={`${BASE_URL}/uploads/${items.photo}`}
                            alt={items.name}
                            key={'item-cart-image' + i}
                            placeholderSrc={logo}
                            width={'80px'}
                            height={'80px'}
                          />
                        </div>
                        <div className="card-body">
                          <div className="card-title text-uppercase">
                            {items.name}
                          </div>
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-8">
                              <h4 className="px-4">
                                &#x20B9;{items.price}/-
                              </h4>
                            </div>
                            <div className="col-4 d-flex justify-content-center">
                              <input
                                type="button"
                                value="-"
                                className="qtyminus minus"
                                field="quantity"
                                onClick={() =>
                                  removeFromCart(items)
                                }
                                disabled={isDisable ? true : false}
                              />
                              <input
                                type="text"
                                name="quantity"
                                value={items.quantity ? items.quantity : 0}
                                className="qty"
                                disabled
                              />
                              <input
                                type="button"
                                value="+"
                                className="qtyplus plus"
                                field="quantity"
                                onClick={() =>
                                  addToCart(items)
                                }
                                disabled={isDisable ? true : false}
                              />
                            </div>
                          </div>
                          <div
                            className="card-text mb-3 mt-3"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: items.description,
                            }}
                            title={(items.description)}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row pt-4 ">
            <div className="col-sm-12">
              <h3 className="serviceInfoTxt mb-3">Why choose {categoryData.name} Services from us? </h3>
              <p className="serviceInfoTxtInfo">{categoryData.description_long}</p>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default CartLocalStorage;
