import React, { useContext, useEffect, useState } from "react";
import logo from "../../images/LOG.png";
import asset03 from "../../images/bell.png";
import asset04 from "../../images/cart.png";
import { NavLink, Link } from "react-router-dom";
import { Base64 } from "js-base64";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ZipcodeModal from "../ZipcodeModal/ZipcodeModal";
import LoginSidebar from "../../Login/LoginSidebar";
import { AppSettingsContext } from "../../Context/AppSettingsProvider";
import { useSelector } from "react-redux";

function Header() {

  const { queryParams, userLoggedIn, cartUpdated } = useContext(AppSettingsContext);
  const cartItems = useSelector((state) => state.cart.items);

  const [areaCodeModal, setAreaCodeModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const [cartLength, setCartLength] = useState(0);

  useEffect(() => {
    calculatCartItems();
    setAreaCodeModal(false);
    if (queryParams != {} && queryParams.logincheck == 1 && userLoggedIn == false) {
      setLoginModal(true);
      navigate("/home/mycart");
    }
  }, [cartUpdated, queryParams, cartItems]);

  let user_name = localStorage.getItem("key2");
  let user_email = localStorage.getItem("key5");

  let user_id = localStorage.getItem("key1");
  let user_zipcode = sessionStorage.getItem('key4');

  const navigate = useNavigate();

  function logout() {
    {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
      toast.info("You have been logged out", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.location.reload();
    }
  }

  function changeArea() {
    setAreaCodeModal(!areaCodeModal);
  }

  function toggleLoginModal() {
    setLoginModal(!loginModal);
  }

  const calculatCartItems = () => {
    let count = JSON.parse(localStorage.getItem('cit'));
    setCartLength(Number.parseInt(count));
  }

  return (
    <div>
      <header className="" style={{ position: 'fixed', width: '100%', zIndex: '1000', top: '0px', boxShadow: '0 0 6px 0 rgba(0,0,0,0.16)' }}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <div className="abs_logo">
                <img src={logo} alt="Logo" className="logo_img" />
              </div>
            </Link>
            {/* <div className="dropdown locationDropdownDiv">
                <button className="btn btn-secondary dropdown-toggle btn-md locationDropdownBtn" type="button" id="locationDropdownBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                onClick={changeArea}
                >
                  { user_zipcode ? Base64.decode(user_zipcode) : 'Select Area' }
                </button>
              </div> */}
            <div className="mobile_icons" >
              <Link className="nav-link" to={`/mynotifications`} title="notifications">
                <i className="fas fa-bell mobile_view"></i>
              </Link>
              <Link className="nav-link" to={`/home/mycart`}>
                {/* <img src={asset04} alt="" className="cart_img mobile_view" /> */}
                <i className="fas fa-cart-plus mobile_view"></i>
                {cartLength > 0 && (<>
                  <span
                    className="position-absolute translate-middle badge rounded-pill bg-danger unique"
                    style={{ marginLeft: "25px", marginTop: "-18px" }}
                  >
                    {cartLength && cartLength}
                  </span>
                </>)}

              </Link>
            </div>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavDropdown"
            >

              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link" : "nav-link"
                    }
                    // className="nav-link"
                    to="tel:+918369441674"
                    style={{ fontWeight: '600' }}
                  >
                    ( Call us : +91 8369441674 )
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    // className="nav-link"
                    to="/aboutus"
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link" : "nav-link"
                    }
                    // className="nav-link"
                    to="/contactus"
                  >
                    Need Help?
                  </NavLink>
                </li>
                {!user_name && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        // className="nav-link"
                        to="#"
                        onClick={toggleLoginModal}
                      >
                        Sign In | Register
                      </NavLink>
                    </li>
                  </>
                )}

                {user_name ? (
                  <>
                    <li className="nav-item desktop_view position-relative">
                      <Link
                        className="nav-link footer-icon"
                        to={`/myorders`}
                      >
                        My Bookings
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {user_name &&
                          Base64.decode(user_name).split(/(\s+)/)[0]}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to={
                              user_email
                                ? `/user_profile/${user_name}/${user_email}`
                                : `/user_profile/${user_name}`
                            }
                          >
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to={`/myaddress`} title="My addresses">
                            My Address
                          </Link>
                        </li>
                        {/* <li>
                          <Link className="dropdown-item" to={`/mynotifications`}>
                            Notifications
                          </Link>
                        </li> */}
                        <li>
                          <Link className="dropdown-item" to={`/myoffers`}>
                            My Offers
                          </Link>
                        </li>
                        <li>
                          <a
                            role="button"
                            className="dropdown-item"
                            onClick={logout}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item desktop_view position-relative">
                      {/* <img src={asset03} alt="" className="notify_img" onClick={showNotificadtionDD} />
                        { showNotification ? <NotificationPop></NotificationPop> : '' } */}
                      <Link className="nav-link" to={`/mynotifications`}>
                        <img src={asset03} alt="" className="notify_img" />
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                <li className="nav-item desktop_view">
                  <Link className="nav-link" to={"/home/mycart"}>
                    <img src={asset04} alt="" className="cart_img" />
                    {
                      cartLength > 0 ?
                        <span className="position-absolute translate-middle badge rounded-pill bg-danger">
                          {cartLength}
                        </span>
                        : ""
                    }
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <ZipcodeModal setAreaCodeModal={setAreaCodeModal} areaCodeModal={areaCodeModal} />
        <LoginSidebar setLoginModal={setLoginModal} loginModal={loginModal} ></LoginSidebar>
      </header>
    </div>
  );
}

export default Header;
