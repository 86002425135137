import React, { useState, useEffect } from "react";
import login from "../images/prof.png";
import { useNavigate, useParams } from "react-router-dom";
import { Base64 } from "js-base64";
import { useFormik, Form } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../Home/Header/Header";
import Footer from "../Footer/Footer";
import { param } from "jquery";
import Header2 from "../Home/Header/Header2";
import { BASE_URL } from "../../../config/constants";
import BackButton from "../Home/Header/BackButton";

function ProfileUpdate(props) {

  const [buttonLoading, setButtonLoading] = useState(false);

  let user_id = localStorage.getItem("key1");
  let user_name = localStorage.getItem("key2");
  user_name = Base64.decode(user_name);

  let user_mobile = localStorage.getItem("key3");
  user_mobile = Base64.decode(user_mobile);

  let user_email = localStorage.getItem("key5");
  user_email = Base64.decode(user_email);

  const Params = useParams();
  const [loading, setLoading] = useState(false);

  let initialValues = {
    user_name: user_name,
    user_mobile: Number.parseInt(user_mobile),
    user_email: user_email
  };

  const validationSchema = yup.object({
    user_name: yup
      .string("Please enter your name")
      .min(3, "Name can not be less than 3 characters long")
      .required("Please enter your name"),
    user_mobile: yup
      .string('Please enter a valid mobile')
      .min(10, "Please enter valid mobile")
      .max(10, "Please enter valid mobile")
      .required("Please enter your mobile"),
    user_email: yup
      .string("Please enter your email")
      .required("Please enter your email")
      .min(5, "Please enter your email")
      .max(255, "Please enter valid email")
      .email('Please enter a valid email')
  });

  const submitForm = (values) => {

    let formData = new FormData();
    formData.append("name", values.user_name);
    formData.append("mobile", values.user_mobile);
    formData.append("email", values.user_email);

    const url = `${BASE_URL}/Api/updateprofile`;

    setButtonLoading(true);
    axios
      .post(url, formData)
      .then((response) => {
        console.log(response);
        setButtonLoading(false);
        // window.location.reload(false);
        localStorage.setItem("key2", Base64.encode(response.data.Data.name));
        localStorage.setItem("key3", Base64.encode(response.data.Data.mobile));
        localStorage.setItem("key5", Base64.encode(response.data.Data.email));

        toast.success("Your Profile Updated Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // alert("Your Profile Update Successfully");
        // props.showAlert("Your Profile Updated Successfully", "success");
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    onSubmit: submitForm,
    validationSchema: validationSchema,
  });


  return (
    <div>
      <div id="prof" className='vh-100'>
        <BackButton></BackButton>
        <div class="container">
          <div class="row bg-white p-4 mt-3 border">
            <div class="login_heading">Manage profile</div>
            <hr />
            <h6 className="mb-4">Update your profile</h6>
            {/* <div class="col-md-6 mt-3" id="login_img_sec">
              <img src={login} alt="login" class="login_img" />
            </div> */}
            <div class="col-md-6">
              <div>
                <form onSubmit={formik.handleSubmit}>
                  <div class=" mb-3">
                    <label htmlFor="user_name" className="mb-2">Full Name : </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_name"
                      name="user_name"
                      placeholder="Full Name"
                      maxLength={20}
                      minLength={3}
                      value={formik.values.user_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                    {formik.touched.user_name && formik.errors.user_name ? <span class="text-danger">{formik.errors.user_name}</span> : null}
                  </div>
                  <div class="mb-3">
                    <label htmlFor="user_mobile" className="mb-2">Contact Number : </label>
                    <input
                      type="text"
                      className="form-control"
                      id="user_mobile"
                      name="user_mobile"
                      placeholder="Mobile Number"
                      minLength="10"
                      maxLength="10"
                      value={formik.values.user_mobile}
                      onChange={formik.handleChange}
                      disabled
                      readOnly
                    />
                    {formik.touched.user_mobile && formik.errors.user_mobile ? <span class="text-danger">{formik.errors.user_mobile}</span> : null}
                  </div>
                  <div class="">
                    <label htmlFor="number" className="mb-2">Email :</label>
                    <input
                      type="email"
                      className="form-control"
                      id="user_email"
                      name="user_email"
                      placeholder="Email"
                      maxLength={255}
                      minLength={5}
                      value={formik.values.user_email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.user_email && formik.errors.user_email ? <span class="text-danger">{formik.errors.user_email}</span> : null}
                  </div>
                  <div class="">
                    {!buttonLoading && (
                      <div class="">
                        <button type="submit"
                          className="btn buttons sitebtn2 mt-4"
                          value="Update"
                        >Update</button>
                      </div>
                    )}
                    {buttonLoading && (
                      <div class="mt-3">
                        <button
                          type="submit"
                          role="button"
                          className="btn buttons"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Updating...
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileUpdate;
