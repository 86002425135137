import React, { useState, useEffect, useCallback } from "react";
import Footer from "../../Footer/Footer";
import Carausel from "../Carausel/Carausel";
import Header from "../Header/Header";
import Services from "../Services/Services";
import Testimonial from "../Testimonial/Testimonial";
import ZipcodeModal from "../ZipcodeModal/ZipcodeModal";
import axios from "axios";
import Header2 from "../Header/Header2";
import Carusel2 from "../Carausel/Carusel2";
import { debounce } from 'lodash'
import { BASE_URL } from "../../../../config/constants";
import ServicesInfo from "../Services/ServicesInfo";
import FloatingFooter from "../../Footer/FloatingFooter";
import { param } from "jquery";


function Home() {
  const [categories, setCategories] = useState([]);
  const [list, setList] = useState([]);

  const [banners, setBanners] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [laptop, setLaptop] = useState([]);

  const [getBanners, setGetBanners] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);

  const get_category_data = () => {
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    let url = `${BASE_URL}/Api/show_category`;

    axios
      .get(url, formData)
      .then((response) => {
        setCategories(response.data.data);
        setPageLoading(false);
        setList([])
      })
      .catch((error) => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_category_data();
  }, []);

  return (
    <div className="site-page">
      <Carausel />
      <Services categories={categories} pageLoading={pageLoading} />
      <ServicesInfo></ServicesInfo>
      <Testimonial />
    </div>
  );
}

export default Home;
