import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Footer/Footer";
import Header from '../Home/Header/Header'
import Loader from "../Loader/Loader";
import Header2 from "../Home/Header/Header2";
import { BASE_URL } from "../../../config/constants";

function Privacy() {
  const [privacyData, setPrivacyData] = useState([]);

  const [pageLoading, setPageLoading] = useState(true);
  const [nullMessage, setNullMessage] = useState(false);


  const get_privacy_data = () => {
    let formData = new FormData(); //formdata object
    setPageLoading(true);

    let url = `${BASE_URL}/Api/privacy_policy`;

    axios
      .get(url, formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setPrivacyData(response.data.Data);
          setPageLoading(false);
        }
        else {
          setNullMessage(true)
          setPageLoading(false);

        }


        // setSubCategories(response.data.products[0].Computer);
        // setLaptop(response.data.products[1].Laptop);
        // setGetBanners(response.data.banners);
        // setNotificationCount(response.data.notification_count);
        // localStorage.setItem("key16",Base64.encode(response.data.notification_count))
        // localStorage.setItem("key14",Base64.encode(response.data.cart_count))
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    get_privacy_data();
  }, []);
  return <div>
    <div className="container marg_top">
      <div className="row mb-5 mt-5 ">
        {pageLoading && (
          <>
            <Loader />
          </>
        )}
        {nullMessage && (
          <>
            <div className="col-md-6">
              <div class="address_container" style={{ padding: "20px" }}>
                <div>Privacy Policy is Not Found</div>
              </div>
            </div>

          </>
        )}
        {privacyData &&
          privacyData.map((item, i) => {
            return (
              <>
                <div
                  class="col-md-12 mt-3"
                  key={i}

                >
                  <div class="address_container">
                    <div class="notification_title text-center">{item.title}</div>
                    <div
                      class="notification_text"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></div>

                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  </div>;
}

export default Privacy;
