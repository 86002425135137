import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import deleteimg from '../images/delete.png'
import { BASE_URL } from "../../../config/constants";


function DeleteAddress(props) {
  // console.log(props);
  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState("");


  // console.log(props);
  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };

  //Delete Address
  const deleteAddress = async (id) => {
    // console.log(id);
    const formData = new FormData();
    // formData.append("user_id", user_id);
    formData.append("address_id", id);

    const url = `${BASE_URL}/Api/addressdelete`;
    setLoading(true);
    axios
      .post(url,formData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.data.status === true) {
          toast.success("Address is Deleted", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          props.getAddressInfo();
          closeModal();
        }
        else{
            toast.error("Something went wrong", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
        }
    //   window.location.reload(false);

      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  function closeModal (){
    setClose(props.hide);
  }
  return (
    <div>
      <div class="modal" show fade style={modalStyle}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {" "}
           Delete Address
              </h5>
              <button
                type="button"
                class="btn-close"
                onClick={props.hide}
              ></button>
            </div>
            <div class="modal-body">
              <div class="cookiesContent" id="cookiesPopup">
                {/* <button class="close">✖</button> */}
                <div className="text-center">
                <i class="fa-solid fa-trash-can text-danger" style={{fontSize:'26px'}}></i>

                </div>
                <br/>
                <p className="text-center">Are You Sure You Want to Delete this Address ?</p>
                <div className="d-flex justify-content-center">
                  <button class="btn buttons mx-2" onClick={props.hide}>
                    No
                  </button>
                  {!loading && (
                    <button
                      type="button"
                      class="btn buttons"
                      onClick={() => deleteAddress(props.address_id)}
                    >
                      Yes
                    </button>
                  )}
                  {loading && (
                    <button className="btn buttons" type="button" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Deleting ...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAddress;
