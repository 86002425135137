import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useSearchParams } from "react-router-dom";

const AppSettingsContext = React.createContext();

function AppSettingsProvider({ children }) {
    const location = useLocation();

    const [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries([...searchParams]);

    const [mobileview, setIsMobileview] = useState(false);
    const [userLoggedIn, setIsUserLogged] = useState(false);

    const [cartUpdated, setCartUpdated] = useState(true);

    useEffect(() => {
        setIsMobileview(isMobile);
    }, [location]);

    const handleResize = () => {
        if (window.innerWidth > 767 && mobileview) {
            setIsMobileview(true);
        } else {
            setIsMobileview(false);
        }
    }

    window.addEventListener('resize', handleResize);

    return (
        <AppSettingsContext.Provider value={
            { mobileview, queryParams, userLoggedIn, setIsUserLogged, cartUpdated, setCartUpdated, location }}>
            {children}
        </AppSettingsContext.Provider>
    )
}

export { AppSettingsContext, AppSettingsProvider };