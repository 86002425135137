import React from 'react'
import { NavLink } from 'react-router-dom';
import validator from 'validator';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import { BASE_URL } from '../../../config/constants';
import axios from 'axios';
import { toast } from "react-toastify";
import { ClientJS } from 'clientjs';

function Contact() {

    let form = {};

    const required = (value) => {
        if (!value.toString().trim().length) {
            return <span className="text-danger">This field is required</span>;
        }
    };

    const email = (value) => {
        if (!validator.isEmail(value)) {
            return <span className="text-danger">Invalid email</span>;
        }
    };

    const lt = (value, props) => {
        if (!value.toString().trim().length > props.maxLength) {
            return <span className="text-danger">The value exceeded {props.maxLength} symbols.</span>
        }
    };

    const num = (value, props) => {
        let pattern = /^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/;
        if (!pattern.test(value.toString().trim())) {
            return <span className="text-danger">Invalid number</span>
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let client = new ClientJS();
        let hashdevice = client.getFingerprint();

        let values = form.getValues();
        let formData = new FormData();
        for (let x in values) {
            formData.set(x + "", values[x]);
        }

        formData.set("Authorization", hashdevice);

        let config = {
            'Content-type': 'application/form-data',
            'Authorization': hashdevice,
        }

        let url = `${BASE_URL}/Api/submit_form`;

        await axios
            .post(url, formData, config)
            .then((response) => {
                if (response.data.status == true) {
                    toast.success("Feedback submitted. We will get back Shortly.", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTimeout(() => window.location = "/", 3000);
                } else {
                    let errormsg = 'Failed, please try again later.';
                    if (response.data.message == 'max_limit') {
                        errormsg = 'We already received your request.';
                    }
                    toast.error(errormsg, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                toast.error("Something went wrong", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });
    }

    return (
        <div>
            <div id="contact" className='marg_top' >
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h5 className='display-5 mb-3 text-center text-white'>Contact us</h5>
                            <div className='text-center mb-3 text-white'>Want us to reach you? Please fill and submit below form</div>
                        </div>
                        <div className="col-lg-6 offset-lg-3 col-sm-12">
                            <div class="office_address">
                                <Form onSubmit={handleSubmit} ref={c => form = c}  >
                                    <div class="mb-4 mt-3">
                                        <Input placeholder="Your Name" type="text" name="name" validations={[required]} className="form-control" required />
                                    </div>
                                    <div class="mb-4">
                                        <Input placeholder="Your contact" type="tel" name="contact" validations={[required, num]} className="form-control pb-2" required />
                                    </div>
                                    <div class="mb-4">
                                        <Input placeholder="Your Email" type="email" name="email" validations={[required, email]} className="form-control" required />
                                    </div>
                                    <div class="mb-4">
                                        <Textarea placeholder="Message" name="message" rows={3} validations={[required, lt]} className="form-control" maxLength={250} required />
                                    </div>
                                    <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                                    <div class=" text-center">
                                        <button type="submit" class="btn loginbtn buttons">Submit</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="address">
                <div className='container mt-3 pt-5 pb-5 bg-white'>
                    <div class="row" >
                        <div class="col-md-4 mt-3">
                            <div className='display-6 mb-5 office_heading'>Visit our office </div>
                            <div class="d-flex align-items-center mb-4">
                                <i class="fa-solid fa-location-dot contact_icon"></i>
                                <div class="contact_text">
                                    Office No.345 3th floor, BMC Building, 78 business park,<br />
                                    nr. Bhandup station road, Bhandup, <br /> West Mumbai, India - 400078
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-4">
                                <i class="fa-solid fa-phone contact_icon"></i>
                                <div class="contact_text">
                                    +91 8369441674
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-4">
                                <i class="fa-solid fa-envelope contact_icon"></i>
                                <div class="contact_text">
                                    caremasterindia@gmail.com
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe
                                        className="gmap_iframe"
                                        width="100%"
                                        src="https://maps.google.com/maps?width=600&height=400&hl=en&q=care master india&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                                    />
                                </div>
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            ".mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {height:400px!important;}"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="faq" style={{ backgroundColor: '#fcfcfc' }} >
                <div className="container">
                    <div className="row pt-5 mb-3">
                        <div className="col-12 mt-3 mb-5">
                            <div className='display-6 mb-5'> Frequently asked questions? </div>
                            {/* <div class="sub_heading lh-3 mb-3">Still have any questions?</div> */}
                            <div class="accordion" id="accordionFaq">
                                <div class="accordion-item">
                                    <h2 class="accordion-header faqheading" id="headingOne">
                                        <button class="accordion-button faqheading" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Q. What is caremaster?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. CareMaster India is home care service provider located in Mumbai.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Q. How to book services on caremaster?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. You can book various home care services from caremaster including plumbing, ac-repair, electrical and more. We offer affordable and reliable services across mumbai and nearby area.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Q. What are the charges for booking?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. Caremaster strive to provide our customers with best and most affordable rates in comparison to other providers.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Q. What are service cancellation charges?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. Caremaster provides affordable rates for all the services on our platform. In case of service cancellation there are minimal charges collected as a convinience fees.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Q. Does caremaster offer doorstep servies?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. Yes, you can book our services online and on call too.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSix">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            Q. Does caremaster serve my locality?
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. We are currently offering services across Mumbai, Thane and New Mumbai. You can try looking for your pincode in area selection to know if the area is servicable.
                                            Soon we would be expanding in more areas.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSeven">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            Q. What kind of repair services do you offer?
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. We offer Carpentor, Electrical, plumbing, painting, pest control and host of other services.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingEight">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            Q. How long have you been in business and what are your credentials?
                                        </button>
                                    </h2>
                                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. We have expert professionals with working experience of 5+ years across the domains.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingNine">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                            Q. Do you have any references or testimonials from previous customers?
                                        </button>
                                    </h2>
                                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. You can always check our testimonial section for host of our happy customers.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTen">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                            Q. How do you handle payments? Do you accept cash, check, credit card, or online payment methods?
                                        </button>
                                    </h2>
                                    <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. We offer all sort of payments. You can opt to pay by cash on service delivery or prebook the services from our web and mobile platforms and pay via UPI / Card or Bank.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingEleven">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                            Q. Do you offer any discounts or promotions for referrals, repeat customers?
                                        </button>
                                    </h2>
                                    <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. Yes. you can always find best of offers on our platform for our regular customers
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwelve">
                                        <button class="accordion-button faqheading collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                            Q. How do you handle any complaints or disputes that may arise during or after the repair work?
                                        </button>
                                    </h2>
                                    <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionFaq">
                                        <div class="accordion-body" style={{ paddingLeft: '1em' }}>
                                            A. Customer satiesfaction is our prime motto. In case of any disputes we do our best to provide the solution.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="call">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center p-5">
                            <div className='display-6 mb-2 office_heading'>Still have Questions / Want to book / enquire about service / need any other help? </div>
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "nav-link" : "nav-link"
                                }
                                to="tel:+918369441674"
                                style={{ marginTop: '20px' }}
                            >
                                <h5>
                                    ( Call us : +91 8369441674 )
                                </h5>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact