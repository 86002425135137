import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Base64 } from "js-base64";
import { BASE_URL } from "../../../../config/constants";

function ZipcodeModal({ areaCodeModal, setAreaCodeModal }) {
  let user_zipcode = sessionStorage.getItem('key4')
  const [open, setOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [description, setDescription] = useState("");
  //   console.log(zipCode);

  const navigate = useNavigate();

  const onKeyPressEvent = (event) => {
    setZipcodeError(false);
    if (event.key === "Enter") {
      post_zipcode();
    }
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue) && keyCode !== 8) event.preventDefault();
    return;
  };
  //------ Post ZIP CODE --------//
  const post_zipcode = async (e) => {
    // e.preventDefault();
    // console.log("run");
    if (!zipCode) {
      toast.error("Please Enter Your Pincode", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      e.preventDefault();
    } else {
      const formData = new FormData();
      formData.append("pincode", zipCode);

      const url = `${BASE_URL}/Api/check_pin`;
      try {
        setButtonLoading(true);
        const data = await axios
          .post(url, formData)
          .then((response) => {
            console.log(response);
            if (response.data.status === false) {
              setButtonLoading(false);
              setZipcodeError(true);
            } else {
              setButtonLoading(false);
              navigate("/");
              setAreaCodeModal(!areaCodeModal);
              // setOpen(false)
              sessionStorage.setItem(
                "key4",
                Base64.encode(zipCode)
              );
            }
          })
          .catch((error) => {
            console.log(error);
            setButtonLoading(false);
            toast.error("Something went wrong", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } catch (error) {
        setButtonLoading(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const togglePincode = () => {
    setAreaCodeModal(!areaCodeModal);
  }

  useEffect(() => {
    // if(!user_zipcode){
    // setOpen(areaCodeModal);
    // }
  }, [])
  return (
    <div>
      <div
        className={"modal fade" + (areaCodeModal ? " show d-block" : " d-none")}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ backgroundColor: "rgba(0,0,0,0.8)", }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header pincodemodal-header">
              <h3 className="modal-title" id="pincodemodal"> {user_zipcode ? 'Change' : 'Enter'} your pincode</h3>
              <button
                type="button"
                className="btn pincodeCloseBtn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={togglePincode}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pb-4">
              <div id="">
                <div className="container p-2">
                  <div className="row">
                    {/* <div className="login_heading">Verify Your Pincode</div> */}
                    <div
                      className="login_text text-center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      hidden={zipcodeError}
                    >
                      {/* <h4 className="mb-3">Enter your pincode.</h4> */}
                    </div>
                    <div
                      className="login_text text-center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                      hidden={!zipcodeError}
                    >
                      <h6 className="mb-3 text-danger">Sorry! entered pincode is not serviceable yet</h6>
                    </div>

                    <div className="login_col">
                      <form>
                        <div
                          className={
                            zipcodeError
                              ? "mb-5 error"
                              : "mb-5 "
                          }
                        >
                          <input
                            type="text"
                            className="form-control pincode-input"
                            minLength="6"
                            maxLength="6"
                            placeholder="eg. 400078"
                            onKeyDown={onKeyPressEvent}
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            autoFocus
                          />
                          {/* <label for="floatingInput">Enter Your Pincode</label> */}
                          {/* {zipcodeError && (
                            <div className="error-message mt-2">
                              Pincode is not Serviceable
                            </div>
                          )} */}
                        </div>
                        {!buttonLoading && (
                          <div className="text-center">
                            <button
                              className="btn btn-lg col-8 mx-auto pincodeBtn"
                              onClick={post_zipcode}
                              type="submit"
                              disabled={!zipCode || zipcodeError}
                            >
                              Next
                            </button>
                          </div>
                        )}
                        {buttonLoading && (
                          <div className="text-center">
                            <button
                              role="button"
                              className="btn buttons"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              Veryfying...
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZipcodeModal;
