import React from 'react'

function Carusel2() {
  return (
    <div></div>
  )
}

export default Carusel2


