import "./App.css";
import AppContainer from "./components/user/AppContainer/AppContainer";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <AppContainer></AppContainer>
      </BrowserRouter>
    </div>
  );
}

export default App;
